import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button, TextField, makeStyles, CssBaseline } from '@material-ui/core';
import { Container } from 'reactstrap';
import * as yup from 'yup';


const schema = yup.object().shape({

  name: yup.string().required(),
  taxIdentificationNumber: yup.string(),
  identificationNumber: yup.string(),
  email: yup.string().required(),
  mobileNumber: yup.string().required(),
  homeNumber: yup.string(),
  officeNumber: yup.string(),
  isCompany: yup.boolean().required(),
  
});

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  // form: {
  //   marginTop: theme.spacing(1)
  // },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const OnlineCustomer = props => {
  const classes = useStyles();
  const { register, handleSubmit, errors, setValue } = useForm({
    validationSchema: schema
  });

  useEffect(() => {
    //Initialize Form
    setValue( 'name', '');
    setValue( 'taxIdentificationNumber', '');
    setValue( 'identificationNumber', '');
    setValue( 'email', '');
    setValue( 'mobileNumber', '');
    setValue( 'homeNumber', '');
    setValue( 'officeNumber', '');
    setValue( 'isCompany', '');

    //If props.selectedObject not null Fill state
    if (props.selectedOnlineCustomer) {
      const onlineCustomer = 
          {      
            name:props.selectedOnlineCustomer.name,
            taxIdentificationNumber:props.selectedOnlineCustomer.taxIdentificationNumber,
            identificationNumber:props.selectedOnlineCustomer.identificationNumber,
            email:props.selectedOnlineCustomer.email,
            mobileNumber:props.selectedOnlineCustomer.mobileNumber,
            homeNumber:props.selectedOnlineCustomer.homeNumber,
            officeNumber:props.selectedOnlineCustomer.officeNumber,
            isCompany:props.selectedOnlineCustomer.isCompany,
          };
              
      //Set Form to received object values
      setValue( 'name', onlineCustomer.name);
      setValue( 'taxIdentificationNumber', onlineCustomer.taxIdentificationNumber);
      setValue( 'identificationNumber', onlineCustomer.identificationNumber);
      setValue( 'email', onlineCustomer.email);
      setValue( 'mobileNumber', onlineCustomer.mobileNumber);
      setValue( 'homeNumber', onlineCustomer.homeNumber);
      setValue( 'officeNumber', onlineCustomer.officeNumber);
      setValue( 'isCompany', onlineCustomer.isCompany);
    }
  }, [props.selectedOnlineCustomer, setValue]);

  //Form Submit
  const onSubmit = data => {
    if (props.selectedOnlineCustomer) {
      data.uniqueId = props.selectedOnlineCustomer.uniqueId;
    }
    props.onSubmit(data);
  };

  return (
    <Container
      component="main"
      maxwidth="xs"
    >
      <CssBaseline />
      <div className={classes.paper}>
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            autoComplete="name"
            autoFocus
            error={errors.name ? true: false}
            fullWidth
            helperText={errors.name && errors.name.message}
            id="name"
            inputRef={register}
            label="Name"
            margin="normal"
            name="name"
            placeholder="Name"
            required
            shrink="true"
            variant="outlined"
          />
          <TextField
            autoComplete="taxIdentificationNumber"
            autoFocus
            error={errors.taxIdentificationNumber ? true: false}
            fullWidth
            helperText={errors.taxIdentificationNumber && errors.taxIdentificationNumber.message}
            id="taxIdentificationNumber"
            inputRef={register}
            label="TaxIdentificationNumber"
            margin="normal"
            name="taxIdentificationNumber"
            placeholder="TaxIdentificationNumber"
            required
            shrink="true"
            variant="outlined"
          />
          <TextField
            autoComplete="identificationNumber"
            autoFocus
            error={errors.identificationNumber ? true: false}
            fullWidth
            helperText={errors.identificationNumber && errors.identificationNumber.message}
            id="identificationNumber"
            inputRef={register}
            label="IdentificationNumber"
            margin="normal"
            name="identificationNumber"
            placeholder="IdentificationNumber"
            required
            shrink="true"
            variant="outlined"
          />
          <TextField
            autoComplete="email"
            autoFocus
            error={errors.email ? true: false}
            fullWidth
            helperText={errors.email && errors.email.message}
            id="email"
            inputRef={register}
            label="Email"
            margin="normal"
            name="email"
            placeholder="Email"
            required
            shrink="true"
            variant="outlined"
          />
          <TextField
            autoComplete="mobileNumber"
            autoFocus
            error={errors.mobileNumber ? true: false}
            fullWidth
            helperText={errors.mobileNumber && errors.mobileNumber.message}
            id="mobileNumber"
            inputRef={register}
            label="MobileNumber"
            margin="normal"
            name="mobileNumber"
            placeholder="MobileNumber"
            required
            shrink="true"
            variant="outlined"
          />
          <TextField
            autoComplete="homeNumber"
            autoFocus
            error={errors.homeNumber ? true: false}
            fullWidth
            helperText={errors.homeNumber && errors.homeNumber.message}
            id="homeNumber"
            inputRef={register}
            label="HomeNumber"
            margin="normal"
            name="homeNumber"
            placeholder="HomeNumber"
            required
            shrink="true"
            variant="outlined"
          />
          <TextField
            autoComplete="officeNumber"
            autoFocus
            error={errors.officeNumber ? true: false}
            fullWidth
            helperText={errors.officeNumber && errors.officeNumber.message}
            id="officeNumber"
            inputRef={register}
            label="OfficeNumber"
            margin="normal"
            name="officeNumber"
            placeholder="OfficeNumber"
            required
            shrink="true"
            variant="outlined"
          />
          <TextField
            autoComplete="isCompany"
            autoFocus
            error={errors.isCompany ? true: false}
            fullWidth
            helperText={errors.isCompany && errors.isCompany.message}
            id="isCompany"
            inputRef={register}
            label="IsCompany"
            margin="normal"
            name="isCompany"
            placeholder="IsCompany"
            required
            shrink="true"
            variant="outlined"
          />
          <Button
            className={classes.submit}
            color="primary"
            fullWidth
            type="submit"
            variant="contained"
          >
            Submit
          </Button>
        </form>
      </div>
    </Container>
  );
};
export default OnlineCustomer;


