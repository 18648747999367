import React, { Fragment, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Header, OnlineVehicleForm, VehicleToolbar } from './components';
import { colors, Tooltip, IconButton, Typography } from '@material-ui/core';
import { DataTable, Label, DeleteConfirm } from 'components';
import * as actionTypes from 'store/OnlineVehicle/OnlineVehicleActions';
import * as generalActionTypes from 'store/General/GeneralActions';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import ReduxBlockUi from 'react-block-ui/redux';
import 'react-block-ui/style.css';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  spacer: {
    flexGrow: 1
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const OnlineVehicleView = () => {
  const classes = useStyles();

  const pageTitle = 'Vehicle';
  const saveUrl = '/Vehicle/createOnlineVehicle';
  const updateUrl = '/Vehicle/updateOnlineVehicle';
  const deleteUrl = '/Vehicle/deleteOnlineVehicle?id=';
  const getUrl = '/Vehicle/getOnlineVehicle?id=';
  const getTableDataUrl = '/Vehicle/getCustomerOnlineVehicles';
  
  const [searchText, setSearchText] = useState();

  const dispatch = useDispatch();
  //Attach States
  const isModalOpen = useSelector(
    state => state.onlineVehicleReducer.showOnlineVehicleModal
  );
  const onlineVehicleObj = useSelector(
    state => state.onlineVehicleReducer.onlineVehicleObj
  );

  const tableInstance = useSelector(
    state => state.onlineVehicleReducer.tableInstance
  );
  const refreshTable = useSelector(
    state => state.onlineVehicleReducer.refreshTable
  );
  const selectedId = useSelector(
    state => state.onlineVehicleReducer.selectedId
  );

  //Attach Dispatch Actions
  const openModal = () => dispatch(actionTypes.showOnlineVehicleModalAction());
  const closeModal = () =>
    dispatch(actionTypes.closeOnlineVehicleModalAction());

  const openTableDeleteConfirm = () =>
    dispatch(actionTypes.openTableDeleteConfirmAction());
  const closeTableDeleteConfirm = () =>
    dispatch(actionTypes.closeTableDeleteConfirmAction());
  const setSelectedId = id => dispatch(actionTypes.setSelectedIdAction(id));

  const setTableSearch = searchText =>
    dispatch(actionTypes.setTableSearch(searchText));

  const saveSelectedOnlineVehicle = (saveUrl, onlineVehicleObj) =>
    dispatch(actionTypes.saveOnlineVehicle(saveUrl, onlineVehicleObj));
  const saveOnlineVehicleDoc = file =>
    dispatch(actionTypes.saveOnlineVehicleDoc(file));
  const updateSelectedOnlineVehicle = (updateUrl, onlineVehicleObj) =>
    dispatch(actionTypes.updateOnlineVehicle(updateUrl, onlineVehicleObj));
  const getSelectedOnlineVehicle = (getUrl, id) =>
    dispatch(actionTypes.fetchOnlineVehicle(getUrl, id));
  const deleteSelectedOnlineVehicle = (deleteUrl, id) =>
    dispatch(actionTypes.deleteOnlineVehicle(deleteUrl, id));

  const statusColors = {
    canceled: colors.grey[600],
    pending: colors.orange[600],
    approved: colors.green[600],
    deactivate: colors.red[600]
  };

  const deleteConfirmContent = (
    <Typography variant="body1" component="p">
      <p>Are you sure you want to delete this vehicle request?</p>
    </Typography>
  );

  const columns = [
    {
      Header: 'UniqueId',
      accessor: 'uniqueId',
      sortType: 'basic',
      searchable: false
    },
    {
      Header: 'Licence #',
      accessor: 'licence',
      sortType: 'basic',
      searchable: true
    },
    {
      Header: 'Colour',
      accessor: 'color',
      sortType: 'basic',
      searchable: false
    },
    {
      Header: 'Status',
      accessor: '',
      disableSortBy: true,
      searchable: false,
      // eslint-disable-next-line
      Cell: cellInfo => {
        return (
          <Fragment>
            <Label color={statusColors.pending} variant="outlined">
              Pending
            </Label>
          </Fragment>
        );
      }
    },
    {
      Header: 'Actions',
      accessor: '',
      searchable: false,
      disableSortBy: true,
      width: 45,
      // eslint-disable-next-line
      Cell: cellInfo => {
        return (
          <Fragment>
            <Tooltip placement="top" title="Edit">
              <IconButton
                aria-label="edit"
                component="span"
                onClick={() => {
                  action(cellInfo.row.original.uniqueId, 'Edit');
                }}
                size="small">
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip placement="top" title="Delete">
              <IconButton
                aria-label="delete"
                component="span"
                onClick={() => {
                  action(cellInfo.row.original.uniqueId, 'Delete');
                }}
                size="small">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Fragment>
        );
      }
    }
  ];
  const sortColumns = useMemo(() => [{ id: 'uniqueId', desc: false }], []);
  const hiddenColumns = useMemo(() => ['uniqueId']);

  function action(id, action) {
    setSelectedId(id);
    switch (action) {
      case 'Edit':
        getSelectedOnlineVehicle(getUrl, id);
        break;
      case 'Delete':
        openTableDeleteConfirm();
        //deleteSelectedOnlineVehicle(deleteUrl, id);

        break;
      default:
        break;
    }
  }

  const newOnlineVehicle = () => {
    openModal();
  };

  const handleSubmit = data => {
    //saveOnlineVehicleDoc(data)
    if (data.id) {
      updateSelectedOnlineVehicle(updateUrl, data);
    } else {
      saveSelectedOnlineVehicle(saveUrl, data);
      //saveOnlineVehicleDoc(data)
    }
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <Header newButtonClick={newOnlineVehicle} />
        <div className={classes.content}>
          <VehicleToolbar
            className={classes.searchInput}
            onSearchChange={searchText =>
              setSearchText(searchText.target.value)
            }
          />
          <span className={classes.spacer} />

          <DataTable
            columns={columns}
            filterClause={tableInstance.filterClause}
            hiddenColumn={hiddenColumns}
            refreshTable={refreshTable}
            searchText={searchText}
            sortColumn={sortColumns}
            url={getTableDataUrl}
          />
          <DeleteConfirm
            isModalOpen={tableInstance.openDeleteConfirm}
            onCloseModal={closeTableDeleteConfirm}
            onProceed={() =>
              deleteSelectedOnlineVehicle(deleteUrl, selectedId)
            }>
            {deleteConfirmContent}
          </DeleteConfirm>
          
            <OnlineVehicleForm
              handleOnSubmit={handleSubmit}
              isModalOpen={isModalOpen}
              onCloseModal={closeModal}
              pageTitle={pageTitle}
              vehicle={onlineVehicleObj}
            />
        </div>
      </div>
    </Fragment>
  );
};

export default OnlineVehicleView;
