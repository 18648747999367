import React, { Fragment, useMemo } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DataTable } from '../../../../components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Divider,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel
} from '@material-ui/core';
import NumberFormat from 'react-number-format';

import { getInitials } from 'helpers';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const TripTable = props => {
  const { className, filterQuery, ...rest } = props;
  const getTableDataUrl = useMemo(() => '/trip/getCustomerTripHistory', []);

  const classes = useStyles();
  const columns = useMemo(() => [
    {
      Header: 'Transaction Date',
      accessor: 'transactionDate',
      sortType: 'basic',
      searchable: true,
      Cell: cellInfo => {
        return (
          <Fragment>
            {moment(cellInfo.row.original.transactionDate).format('DD/MM/YYYY | h:mm A')}
          </Fragment>
        ) }

    },
    {
      Header: 'Ticket #',
      accessor: 'ticketNumber',
      sortType: 'basic',
      searchable: false,

    },
    {
      Header: 'Licence #',
      accessor: 'licence',
      sortType: 'basic',
      searchable: false

    },
    {
      Header: 'Classification',
      accessor: 'classification',
      sortType: 'basic',
      searchable: false,

    },
    {
      Header: 'Method',
      accessor: 'chargeType',
      sortType: 'basic',
      searchable: false,

    },
    {
      Header: 'Amount',
      accessor: 'amount',
      sortType: 'basic',
      searchable: false,
      Cell: cellInfo => {
        return (
          <NumberFormat
            displayType={'text'}
            prefix={'$'}
            thousandSeparator
            value={cellInfo.row.original.amount}
          />          
        ) }

    }
  ], []);
  const sortColumns = useMemo(() => [{ id: 'transactionDate', desc: true }], [])
  const hiddenColumns = useMemo(() => [], [])
  return (
    <Fragment>
      <div className={classes.root}>
        <div className={classes.content}>
          <DataTable
            columns={columns}
            filterClause={filterQuery}
            hiddenColumn={hiddenColumns}
            sortColumn={sortColumns}
            url={getTableDataUrl}
          />
        </div>
      </div>
    </Fragment>
  );
};

TripTable.propTypes = {
  className: PropTypes.string
};

export default TripTable;
