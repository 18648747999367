import Axios from '../../axios/axios';
import {
  showSuccessAlertAction,
  showErrorAlertAction,
  blockInputAction,
  unblockInputAction
} from '../General/GeneralActions';

import { showLoading, hideLoading } from 'react-redux-loading-bar';

export const SHOW_REQUEST_MODAL = 'SHOW_REQUEST_MODAL';
export const CLOSE_REQUEST_MODAL = 'CLOSE_REQUEST_MODAL';
export const SHOW_REQUEST_ALERT = 'SHOW_REQUEST_ALERT';
export const CLOSE_REQUEST_ALERT = 'CLOSE_REQUEST_ALERT';

export const SET_VEHICLE_REQUEST = 'SET_VEHICLE_REQUEST';
export const SET_CUSTOMER_REQUEST = 'SET_CUSTOMER_REQUEST';
export const DELETE_REQUEST = 'DELETE_REQUEST';
export const SAVE_REQUEST = 'SAVE_REQUEST';
export const UPDATE_REQUEST = 'UPDATE_REQUEST';
export const ACTION_FAILED = 'ACTION_FAILED';
export const GET_CLASSIFICATIONS = 'GET_CLASSIFICATIONS';

export const SET_TABLE_SEARCH = 'SET_TABLE_SEARCH';
export const SET_TABLE_FILTER = 'SET_TABLE_FILTER';
export const SET_TABLE_LOADING = 'SET_TABLE_LOADING';
export const REFRESH_TABLE = 'REFRESH_TABLE';
export const REFRESH_TABLE_COMPLETE = 'REFRESH_TABLE_COMPLETE';

//MODAL ACTIONS
export const showRequestModalAction = () => ({ type: SHOW_REQUEST_MODAL });
export const closeRequestModalAction = () => ({ type: CLOSE_REQUEST_MODAL });

//ALERT ACTIONS
export const showRequestAlertAction = () => ({ type: SHOW_REQUEST_ALERT });
export const closeRequestAlertAction = () => ({ type: CLOSE_REQUEST_ALERT });

//TABLE ACTIONS
export const setTableSearch = payload => ({
  type: SET_TABLE_SEARCH,
  payload: payload
});

export const setTableFilter = payload => ({
  type: SET_TABLE_FILTER,
  payload: payload
});

export const refreshTable = () => ({
  type: REFRESH_TABLE
});

export const refreshTableComplete = () => ({
  type: REFRESH_TABLE_COMPLETE
});

//DATA ACTIONS
export const setVehicleRequestAction = requestObj => ({
  type: SET_VEHICLE_REQUEST,
  payload: requestObj
});
export const setCustomerRequestAction = requestObj => ({
  type: SET_CUSTOMER_REQUEST,
  payload: requestObj
});

export const deleteRequestAction = deleteResponse => ({
  type: DELETE_REQUEST,
  payload: deleteResponse
});

export const saveRequestAction = saveResponse => ({
  type: SAVE_REQUEST,
  payload: saveResponse
});

export const updateRequestAction = updateResponse => ({
  type: UPDATE_REQUEST,
  payload: updateResponse
});

export const getClassificationAction = requestObj => ({
  type: GET_CLASSIFICATIONS,
  payload: requestObj
});

export const fetchRequest = (getUrl, id, type) => {
  return dispatch => {
    dispatch(showLoading());
    Axios.get(getUrl + id)
      .then(response => {
        dispatch(hideLoading());

        const data = response.data;
        if (!data.success) {
          dispatch(showErrorAlertAction({ message: data.message }));
        } else {
          if (type === 'Customer Information') {
            dispatch(setCustomerRequestAction(data.data));
          } else if (type === 'New Vehicle') {
            dispatch(setVehicleRequestAction(data.data));
          }
        }
      })
      .catch(error => {
        dispatch(showErrorAlertAction());
      });
  };
};

export const deleteRequest = (deleteUrl, id) => {
  return dispatch => {
    dispatch(blockInputAction());
    dispatch(showLoading());

    Axios.delete(deleteUrl + id)
      .then(response => {
        dispatch(hideLoading());

        const data = response.data;
        if (!data.success) {
          dispatch(showErrorAlertAction({ message: data.message }));
        } else {
          dispatch(deleteRequestAction(data));
          dispatch(showSuccessAlertAction({ message: data.message }));
          dispatch(refreshTable());
        }
      })
      .catch(error => {
        dispatch(showErrorAlertAction());
      })
      .finally(() => {
        dispatch(refreshTableComplete());
        dispatch(unblockInputAction());
      });
  };
};

export const saveRequest = (saveUrl, requestObj) => {
  return dispatch => {
    dispatch(blockInputAction());
    dispatch(showLoading());

    const formData = new FormData();

    formData.append('Document', requestObj.file);
    formData.append('LicencePlate', requestObj.licencePlateNumber);
    formData.append('Color', requestObj.color);

    Axios.post(saveUrl, formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
      .then(response => {
        dispatch(hideLoading());

        const data = response.data;
        if (!data.success) {
          dispatch(showErrorAlertAction({ message: data.message }));
        } else {
          dispatch(saveRequestAction(data));
          dispatch(showSuccessAlertAction({ message: data.message }));
          dispatch(refreshTable());
        }
      })
      .catch(error => {
        dispatch(showErrorAlertAction());
      })
      .finally(() => {
        dispatch(refreshTableComplete());
        dispatch(unblockInputAction());
      });
  };
};

export const saveRequestDoc = data => {
  return dispatch => {
    dispatch(blockInputAction());
    dispatch(showLoading());
    const formData = new FormData();

    formData.append('Document', data.file);
    formData.append('FkRequest', '9f1d2982-ad8d-41f6-9406-ade5803120ce');
    // const vehicleObj = {
    //   LicencePlate: requestObj.licencePlateNumber,
    //   Color: requestObj.color,
    //   Customer: requestObj.fkOnlineCustomer
    // };

    Axios.post('vehicle/createRequestDocument', formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
      .then(response => {
        dispatch(hideLoading());

        const data = response.data;
        if (!data.success) {
          dispatch(showErrorAlertAction({ message: data.message }));
        } else {
          //dispatch(saveOnlineVehicleAction(data));
          dispatch(showSuccessAlertAction({ message: data.message }));
        }
      })
      .catch(error => {
        dispatch(showErrorAlertAction());
      })
      .finally(() => {
        dispatch(refreshTableComplete());
        dispatch(unblockInputAction());
      });
  };
};

export const updateRequest = (updateUrl, requestObj) => {
  return dispatch => {
    dispatch(blockInputAction());
    dispatch(showLoading());

    Axios.post(updateUrl, requestObj, {})
      .then(response => {
        dispatch(hideLoading());

        const data = response.data;
        if (!data.success) {
          dispatch(showErrorAlertAction({ message: data.message }));
        } else {
          dispatch(updateRequestAction(data));
          dispatch(showSuccessAlertAction({ message: data.message }));
          dispatch(refreshTable());
        }
      })
      .catch(error => {
        dispatch(showErrorAlertAction());
      })
      .finally(() => {
        dispatch(refreshTableComplete());
        dispatch(unblockInputAction());
      });
  };
};

export const getClassifications = getUrl => {
  return dispatch => {
    dispatch(showLoading());
    Axios.post(getUrl)
      .then(response => {
        dispatch(hideLoading());
        const data = response.data;
        if (!data.success) {
          dispatch(showErrorAlertAction({ message: data.message }));
        } else {
          dispatch(getClassificationAction(data.data));
        }
      })
      .catch(error => {
        dispatch(showErrorAlertAction());
      });
  };
};

export const downloadRegistration = vehicleId => {
  return dispatch => {
    dispatch(blockInputAction());
    dispatch(showLoading());
    Axios.get('/vehicle/getVehicleDocuments?item=' + vehicleId)
      .then(response => {
        dispatch(hideLoading());
        const data = response.data;
        if (!data.success) {
          dispatch(showErrorAlertAction({ message: data.message }));
        } else {
          window.open(
            'https://dhbcinternalapi.ssi.gy/harbourbridgeportalapitest/api/vehicle/downloadVehicleDocument?item=' + data?.data[0].id, "_blank");
          // Axios.get('/vehicle/downloadVehicleDocument?item=' + data?.data[0].id)
          //   .then(res => {
          //     const data1 = res.data;
          //     if (!data1.success) {
          //       dispatch(showErrorAlertAction({ message: data1.message }));
          //     }
          //   })
          //   .catch(error => {
          //     dispatch(showErrorAlertAction());
          //   });
        }
      })
      .catch(error => {
        dispatch(showErrorAlertAction());
      })
      .finally(() => {
        dispatch(unblockInputAction());
      });
  };
};
