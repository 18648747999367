import Axios from '../../axios/axios';
import {
  showSuccessAlertAction,
  showErrorAlertAction,
  blockInputAction,
  unblockInputAction
} from '../General/GeneralActions';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

export const SET_ONLINECUSTOMER = 'SET_ONLINECUSTOMER';
export const UPDATE_ONLINECUSTOMER = 'UPDATE_ONLINECUSTOMER';

//DATA ACTIONS
export const setOnlineCustomerAction = onlineCustomerObj => ({
  type: SET_ONLINECUSTOMER,
  payload: onlineCustomerObj
});

export const updateOnlineCustomerAction = updateResponse => ({
  type: UPDATE_ONLINECUSTOMER,
  payload: updateResponse
});

export const fetchOnlineCustomer = (getUrl, id) => {
  return dispatch => {
    dispatch(showLoading());

    Axios.get(getUrl + id)
      .then(response => {
        dispatch(hideLoading());

        const data = response.data;
        if (!data.success) {
          dispatch(showErrorAlertAction({ message: data.message }));
        } else {
          dispatch(setOnlineCustomerAction(data.data));
        }
      })
      .catch(error => {
        dispatch(showErrorAlertAction());
      });
  };
};

export const updateOnlineCustomer = (updateUrl, onlineCustomerObj) => {
  return dispatch => {
    dispatch(blockInputAction());
    dispatch(showLoading());

    Axios.patch(
      updateUrl + '?id=' + onlineCustomerObj.uniqueId,
      onlineCustomerObj
    )
      .then(response => {
        dispatch(hideLoading());

        const data = response.data;
        if (!data.success) {
          dispatch(
            showErrorAlertAction({
              message: data.message
            })
          );
        } else {
          dispatch(updateOnlineCustomerAction(data));
          dispatch(showSuccessAlertAction({ message: data.message }));
        }
      })
      .catch(error => {
        dispatch(showErrorAlertAction());
      })
      .finally(() => {
        dispatch(unblockInputAction());
      });
  };
};
