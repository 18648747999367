import * as actionTypes from './OnlineCustomerActions';

const initialState = {
  onlineCustomerObj: null
};

const  onlineCustomerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_ONLINECUSTOMER:
      return {
        ...state,
        onlineCustomerObj: payload
      };
      
    case actionTypes.UPDATE_ONLINECUSTOMER:
      return {
        ...state,
        //onlineCustomerObj: null
      };

    default:

      return state;
  }
};

export default  onlineCustomerReducer;
