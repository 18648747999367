import Axios from 'axios/axios';

export const SHOW_USER_MODAL = 'SHOW_USER_MODAL';
export const CLOSE_USER_MODAL = 'CLOSE_USER_MODAL';
export const SHOW_USER_ALERT = 'SHOW_USER_ALERT';
export const CLOSE_USER_ALERT = 'CLOSE_USER_ALERT';

export const SET_USER = 'SET_USER';
export const FETCH_USER_FAILED = 'FETCH_USER_FAILED';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED';
export const SAVE_USER = 'SAVE_USER';
export const SAVE_USER_FAILED = 'SAVE_USER_FAILED';
export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_ACCOUNT_BALANCE_INFO = 'GET_ACCOUNT_BALANCE_INFO';
export const GET_RECENT_TRIPS = 'GET_RECENT_TRIPS';
export const GET_REQUESTED_VEHICLES = 'GET_REQUESTED_VEHICLES';
export const SET_REQUESTED_VEHICLES = 'SET_REQUESTED_VEHICLES';
export const GET_REQUESTED_VEHICLES_FAILED = 'GET_REQUESTED_VEHICLES_FAILED';
export const SET_REQUESTED_VEHICLE = 'SET_REQUESTED_VEHICLE';
export const GET_REQUESTED_VEHICLE_FAILED = 'GET_REQUESTED_VEHICLE_FAILED';

export const SAVE_REQUESTED_VEHICLE_FAILED = 'GET_REQUESTED_VEHICLE_FAILED';


//MODAL ACTIONS
export const showUserModalAction = () => ({ type: SHOW_USER_MODAL });
export const closeUserModalAction = () => ({ type: CLOSE_USER_MODAL });

//ALERT ACTIONS
export const showUserAlertAction = () => ({ type: SHOW_USER_ALERT });
export const closeUserAlertAction = () => ({ type: CLOSE_USER_ALERT });

//DATA ACTIONS
export const setUserAction = userObj => ({ type: SET_USER, payload: userObj });
export const fetchUserFailedAction = fetchUserResponse => ({
  type: FETCH_USER_FAILED,
  payload: fetchUserResponse
});
export const deleteUserAction = deleteResponse => ({
  type: DELETE_USER,
  payload: deleteResponse
});
export const deleteUserFailedAction = deleteResponse => ({
  type: DELETE_USER_FAILED,
  payload: deleteResponse
});

export const saveUserAction = saveResponse => ({
  type: SAVE_USER,
  payload: saveResponse
});
export const saveUserFailedAction = saveResponse => ({
  type: SAVE_USER_FAILED,
  payload: saveResponse
});
export const getUserInfoAction = userInfo => ({
  type: GET_USER_INFO,
  payload: userInfo
});
export const getAccountBalanceAction = accountBalanceInfo => ({
  type: GET_ACCOUNT_BALANCE_INFO,
  payload: accountBalanceInfo
});
export const getRecentTripsAction = recentTrips => ({
  type: GET_RECENT_TRIPS,
  payload: recentTrips
});
export const setRequestedVehiclesAction = vehicles => ({
  type: SET_REQUESTED_VEHICLES,
  payload: vehicles
});
export const fetchRequestedVehiclesFailedAction = error => ({
  type: GET_REQUESTED_VEHICLES_FAILED,
  payload: error
});
export const setRequestedVehicleAction = vehicle => ({
  type: SET_REQUESTED_VEHICLE,
  payload: vehicle
});
export const fetchRequestedVehicleFailedAction = error => ({
  type: GET_REQUESTED_VEHICLE_FAILED,
  payload: error
});

export const fetchDashboardInformation = (getUrl) => {
  return dispatch => {
    Axios.get(getUrl)
      .then(response => {

        const data = response.data;
        if (!data.success) {
          // dispatch(
          //   fetchUserFailedAction({ message: data.message, severity: 'error' })
          // );
        } else {
          dispatch(getAccountBalanceAction(data.data.accountInformation));
          dispatch(getUserInfoAction(data.data.customerInformation));
          dispatch(getRecentTripsAction(data.data.tripInformation));
        }
      })
      .catch(error => {
        dispatch(fetchUserFailedAction({ message: error, severity: 'error' }));
      });
  };
};
export const fetchRequestedVehicles = (getUrl, id) => {
  return dispatch => {
    Axios.get(getUrl + id)
      .then(response => {

        const data = response.data;
        if (!data.success) {
          dispatch(fetchRequestedVehiclesFailedAction({ message: data.message, severity: 'error' }));
         
        } else {
          dispatch(setRequestedVehiclesAction(data.data));
        }
      })
      .catch(error => {
        dispatch(fetchRequestedVehiclesFailedAction({ message: error, severity: 'error' }));
      });
  };
};
export const fetchRequestedVehicle = (getUrl, id) => {
  return dispatch => {
    Axios.get(getUrl + id)
      .then(response => {

        const data = response.data;
        if (!data.success) {
          dispatch(fetchRequestedVehicleFailedAction({ message: data.message, severity: 'error' }));
          
        } else {
          dispatch(setRequestedVehicleAction(data.data));
        }
      })
      .catch(error => {
        dispatch(fetchRequestedVehicleFailedAction({ message: error, severity: 'error' }));
      });
  };
};

export const saveRequestedVehicle = (url, data) => {
  return dispatch => {
    Axios.post(url, data)
      .then(response => {

        const data = response.data;
        if (!data.success) {
          dispatch(fetchRequestedVehicleFailedAction({ message: data.message, severity: 'error' }));
          
        } else {
          dispatch(setRequestedVehicleAction(null));
        }
      })
      .catch(error => {
        dispatch(fetchRequestedVehicleFailedAction({ message: error, severity: 'error' }));
      });
  };
};


export const deleteUser = (deleteUrl, id) => {
  return dispatch => {
    Axios.post(deleteUrl + id)
      .then(response => {
        const data = response.data;
        if (!data.success) {
          dispatch(
            deleteUserFailedAction({ message: data.message, severity: 'error' })
          );
        } else {
          dispatch(deleteUserAction(data));
        }
      })
      .catch(error => {
        dispatch(deleteUserFailedAction({ message: error, severity: 'error' }));
      });
  };
};

export const saveUser = (saveUrl, userObj) => {
  return dispatch => {
    Axios.post(saveUrl, userObj)
      .then(response => {
        const data = response.data;
        if (!data.success) {
          dispatch(
            saveUserFailedAction({
              message: data.message,
              severity: 'error'
            })
          );
        } else {
          dispatch(saveUserAction(data));
        }
      })
      .catch(error => {
        dispatch(saveUserFailedAction({ message: error, severity: 'error' }));
      });
  };
};
