import React from 'react';
//import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
//import * as actionTypes from 'store/Actions';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  welcome: {
    marginTop: theme.spacing(1)
  },
  name: {
    textAlign: 'center'
  }
}));

const Profile = props => {
  const { className, ...rest } = props;
  //const dispatch = useDispatch();
  const userInfo = useSelector(state => state.mainReducer.userInfo);

  //Attach Dispatch Actions
  //const fetchDashboardInformation = useCallback((url,id) => dispatch(actionTypes.fetchDashboardInformation(url,id)), [dispatch]);
  
  // useEffect(() => {
  //   fetchDashboardInformation('/dashboard/getDashboard?id=',10035);
  // }, [fetchDashboardInformation]);

  const classes = useStyles();

  // const user = {
  //   name: userInfo.name,
  //   avatar: '/images/avatars/avatar_11.png',
  //   bio: 'Brain Director'
  // };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      {/* <Avatar
        alt="Person"
        className={classes.avatar}
        component={RouterLink}
        src={user.avatar}
        to="/settings"
      /> */}
      <Typography
        className={classes.welcome}
        variant="h4"
      >
        Welcome
      </Typography>
      <Typography
        className={classes.name}
        variant="body1"
      >{userInfo.name}</Typography>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
