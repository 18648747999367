import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Typography, Badge, Hidden, IconButton,Tooltip } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
//import logo from "/images/logos/logo.png";

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  brand: {
    color:'#ffffff'
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  logo: {
    maxWidth: 70,
    marginTop: '5px',
    marginBottom: '5px',
    // marginLeft: '30px'
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, onLogOutClick, ...rest } = props;

  const classes = useStyles();

  const [notifications] = useState([]);

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <RouterLink to="/" >
        <img src="/images/logos/logo.png" alt="Breeze Pass" className={classes.logo} />
          {/* <Typography
            className={classes.brand}
            variant="h4"
          >
          DHBC Portal
          </Typography> */}
        </RouterLink>
        <div className={classes.flexGrow} />
        {/* <IconButton color="inherit">
          <Badge
            badgeContent={notifications.length}
            color="primary"
            variant="dot"
          >
            <NotificationsIcon />
          </Badge>
        </IconButton> */}
        <Tooltip
          placement="bottom"
          title="Log out"
        >
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={onLogOutClick}
          >
            <InputIcon />
          </IconButton>
        </Tooltip>
        
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
