import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Typography, Paper, Input } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { SearchInput, FilterDateInput } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  filterButton: {
    marginRight: theme.spacing(1)
  },
  cancelButton: {
    
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const PaymentHistoryToolbar = props => {
  const { className, onStartDateChange, onEndDateChange,selectedStartDate, selectedEndDate, onFilterClick, onResetClick, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <Typography
          className={classes.title}
          variant="h4"
        >Filter By</Typography>
      </div>
      <div className={classes.row}>
        {/* <SearchInput
          className={classes.searchInput}
          onChange={onLicenceNumberChange}
          placeholder="Licence Plate Number"
          value={licenceNumber}
        /> */}
        <FilterDateInput
          className={classes.searchInput}
          onChange={onStartDateChange}
          placeholder="Start Date"
          selectedDate={selectedStartDate}
        />
        <FilterDateInput
          className={classes.searchInput}
          onChange={onEndDateChange}
          placeholder="End Date"
          selectedDate={selectedEndDate}
        />
        <Button
          className={classes.filterButton}
          color="primary"
          onClick={() => onFilterClick()}
          size="large"
          variant="contained"

        >
          Filter
        </Button>
        <Button
          className={classes.cancelButton}
          color="default"
          onClick={() => onResetClick()}
          size="large"
          variant="contained"

        >
          Reset
        </Button>
      </div>
    </div>
  );
};

PaymentHistoryToolbar.propTypes = {
  className: PropTypes.string
};

export default PaymentHistoryToolbar;
