import React, {useEffect} from 'react';
import { useForm } from 'react-hook-form'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSelector, useDispatch } from 'react-redux';

const VehicleFormModal = props => {
  const [open, setOpen] = React.useState(false);
  const [vehicle, setVehicle] = React.useState({});
  const { register, handleSubmit, watch, errors, setValue } = useForm()
  const {openModal, handleClose,onSave} = props;

  const dispatch = useDispatch();
  //Attach States
  const selectedVehicle = useSelector(state => state.selectedRequestedVehicle);
  
  useEffect(() => {
    setValue('plate', '');
    setValue('colour', '');

    if (selectedVehicle) {
      setValue('plate', selectedVehicle.licencePlateNumber);
      setValue('colour', selectedVehicle.color);
    }

    // setUserMessage(props.message);
  }, [selectedVehicle, setValue]);

  const onLicenceChange = (event) => {
    setValue( 'licencePlateNumber', event.target.value.toUpperCase().replace(/\s/g, ''));
  };

  const onSubmit = data => {

    onSave(data) 
  }
  const handleClickOpen = () => {
    setOpen(openModal);
  };

  return (
    <div>
      <Button
        color="primary"
        onClick={handleClickOpen}
        variant="outlined"
      >
        Open form dialog
      </Button>
      <Dialog
        aria-labelledby="form-dialog-title"
        onClose={() => handleClose()}
        open={openModal}
      >
        <DialogTitle id="form-dialog-title">Request Vehicle</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
          
            <TextField
              autoFocus
              fullWidth
              inputRef={register}
              label="Licence Plate #"
              name="plate"
              type="text"
              variant="outlined"
              onClick={onLicenceChange}
            />
            <TextField
              fullWidth
              inputRef={register}
              label="Vehicle Colour"
              name="colour"
              shrink={selectedVehicle.color?true:false}
              style={{ marginTop: '1rem' }}
              type="text"
              variant="outlined"
            />
          
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={handleClose}
            >
            Cancel
            </Button>
            <Button
              color="primary"
              type="submit"
            >
            Request
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
export default VehicleFormModal;
