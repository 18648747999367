import React, { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { DropzoneArea } from 'material-ui-dropzone';
import {
  Button,
  TextField,
  makeStyles,
  Chip,
  CssBaseline,
  Grid,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import * as actionTypes from 'store/General/GeneralActions';
import RootRef from '@material-ui/core/RootRef';
import { Container } from 'reactstrap';
import AtttachmentIcon from '@material-ui/icons/AttachFile';
import ReduxBlockUi from 'react-block-ui/redux';
import * as yup from 'yup';

const schema = yup.object().shape({
  licencePlateNumber: yup
    .string()
    .required(() => 'Licence Plate Number is required'),
  color: yup.string().required(() => 'Colour is required')
});

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  dropzoneArea: {
    minHeight: 0
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const OnlineVehicle = props => {
  const {
    vehicle,
    onCloseModal,
    isModalOpen,
    pageTitle,
    handleOnSubmit
  } = props;
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [fileId, setFileId] = useState();
  const classes = useStyles();
  const { register, handleSubmit, errors, setValue } = useForm({
    validationSchema: schema
  });

  const dispatch = useDispatch();

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    acceptedFiles.forEach(file => {
      const reader = new FileReader();      
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
      };
      reader.readAsArrayBuffer(file);
      handleOnSubmit(file);
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const { ref, ...rootProps } = getRootProps();

  // Form Submit
  const onSubmit = data => {
    if (vehicle) {
      data.id = vehicle.id;
    }
    if (file) {
      data.file = file;
      data.fileId = fileId;
      data.fileName = fileName;
    }
    if(!fileName) {
      dispatch(actionTypes.showErrorAlertAction({message: 'Vehicle Registration upload is required'}))
      return;
    }

    handleOnSubmit(data);
  };
  const handleEntered = () => {
    setFile(null);
    setFileName(null);
    setFileId(null);
    setValue('licencePlateNumber', '');
    setValue('color', '');
    //If props.selectedObject not null Fill state
    if (vehicle) {
      //Set Form to received object values
      setValue('licencePlateNumber', vehicle.licencePlate);
      setValue('color', vehicle.color);
      if (vehicle.documentInfo) {
        setFileName(vehicle.documentInfo.fileName);
        setFileId(vehicle.documentInfo.id);
      }
    }
  };
  const onLicenceChange = event => {
    setValue(
      'licencePlateNumber',
      event.target.value.toUpperCase().replace(/[^a-zA-Z0-9]/g, '')
    );
  };
  const handleChange = files => {
    files.forEach(loadedFile => {
      setFile(loadedFile);
      setFileName(loadedFile.name);
    });
  };
  const handleDelete = () => {
    setFile(null);
    setFileName(null);
    setFileId(null);
  };

  let uploadBlock = '';
  if (fileName) {
    uploadBlock = (
      <div>
        <label>Vehicle Registration: </label>
        <Chip
          color="primary"
          icon={<AtttachmentIcon />}
          label={fileName}
          onDelete={handleDelete}
        />
      </div>
    );
  } else {
    uploadBlock = (
      <DropzoneArea
        acceptedFiles={['image/*', 'application/pdf']}
        maxFileSize={5242880}
        ClassName={classes.dropzoneArea}
        dropzoneText="Please upload a copy of your vehicle registration*"
        filesLimit={1}
        required
        onChange={files => handleChange(files)}
        showPreviewsInDropzone={false}
      />
    );
  }

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      onClose={onCloseModal}
      onEntered={() => handleEntered()}
      open={isModalOpen}>
        <ReduxBlockUi
            tag="div"
            block={actionTypes.BLOCK_INPUT}
            unblock={actionTypes.UNBLOCK_INPUT}
            onChange={() => {}}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="form-dialog-title">{pageTitle}</DialogTitle>
        <CssBaseline />
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <TextField
                error={errors.licencePlateNumber ? true : false}
                fullWidth
                helperText={
                  errors.licencePlateNumber && errors.licencePlateNumber.message
                }
                InputLabelProps={{ shrink: true }}
                inputRef={register}
                label="Licence Plate Number"
                name="licencePlateNumber"
                placeholder="Licence Plate Number"
                required
                variant="outlined"
                onChange={onLicenceChange}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                error={errors.color ? true : false}
                fullWidth
                helperText={errors.color && errors.color.message}
                InputLabelProps={{ shrink: true }}
                inputRef={register}
                label="Colour"
                name="color"
                placeholder="Colour"
                required
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              {/* <RootRef rootRef={ref}>
                <Paper {...rootProps}>
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop some files here, or click to select files</p>
                </Paper>
              </RootRef> */}
              {uploadBlock}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onCloseModal} variant="outlined">
            Cancel
          </Button>
          <Button color="primary" type="submit" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </form>
      </ReduxBlockUi>
    </Dialog>
  );
};
export default OnlineVehicle;
