import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    flexBasis: 420
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  input: {
    flexGrow: 1,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '-0.05px'
  }
}));

const FilterDateInput = props => {
  const { className, onChange, selectedDate, placeholder, style, ...rest } = props;

  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Paper
        {...rest}
        className={clsx(classes.root, className)}
        style={style}
      >
        <KeyboardDatePicker
          autoOk
          className={classes.input}
          format="MM/dd/yyyy"
          InputAdornmentProps={{ position: 'start' }}
          onChange={date => onChange(date)}
          value={selectedDate}
          placeholder={placeholder}
          variant="inline"
        />
      </Paper>
    </MuiPickersUtilsProvider>
  );
};

FilterDateInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  selectedDate: PropTypes.object,
  style: PropTypes.object
};

export default FilterDateInput;
