import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  FormHelperText,
  Checkbox,
  Paper,
  Typography
} from '@material-ui/core';
import * as actionTypes from '../../store/Auth/AuthActions';
import * as generalActionTypes from 'store/General/GeneralActions';
import ReduxBlockUi from 'react-block-ui/redux';
import * as yup from 'yup';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required('Email Address is required'),
  name: yup.string().required('Full Name is required'),
  password: yup
    .string()
    .required('Password is required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
      'Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
    ),
  confirm_password: yup
    .string()
    .required('Confirm Password is required')
    .oneOf([yup.ref('password')], 'Your passwords do not match.')
});

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    marginTop: theme.spacing(5),
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  card: {
    marginTop: theme.spacing(3)
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  policy: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  signUpButton: {
    margin: theme.spacing(2, 0)
  }
}));

const SignUp = props => {
  const { history } = props;

  const dispatch = useDispatch();
  const classes = useStyles();

  const registerUser = userObj => dispatch(actionTypes.registerUser(userObj));

  const { register, handleSubmit, errors, setValue } = useForm({
    mode: 'onBlur',
    validationSchema: schema
  });

  //Form Submit
  const onSubmit = data => {
    registerUser(data);
  };

  const handleSignUp = event => {
    event.preventDefault();
    history.push('/');
  };

  return (
    <div className={classes.root}>
      <ReduxBlockUi
        tag="div"
        block={generalActionTypes.BLOCK_INPUT}
        unblock={generalActionTypes.UNBLOCK_INPUT}
        onChange={() => {}}>
        <Grid
          alignItems="center"
          className={classes.grid}
          container
          justify="center">
          <Grid className={classes.content} item lg={6} xs={12}>
            <div className={classes.content}>
              <div className={classes.contentBody}>
                <form
                  className={classes.form}
                  noValidate
                  onSubmit={handleSubmit(onSubmit)}>
                  <Card raised>
                    <CardContent>
                      <Typography className={classes.title} variant="h2">
                        Create new account
                      </Typography>
                      <Typography color="textSecondary" gutterBottom>
                        Use your email to create new account
                      </Typography>
                      <TextField
                        className={classes.textField}
                        error={errors.name ? true : false}
                        fullWidth
                        helperText={errors.name && errors.name.message}
                        inputRef={register}
                        label="Full Name"
                        name="name"
                        placeholder="Full Name"
                        required
                        variant="outlined"
                      />
                      <TextField
                        className={classes.textField}
                        error={errors.email ? true : false}
                        fullWidth
                        helperText={errors.email && errors.email.message}
                        inputRef={register}
                        label="Email Address"
                        name="email"
                        placeholder="Email Address"
                        required
                        variant="outlined"
                      />
                      <TextField
                        className={classes.textField}
                        error={errors.password ? true : false}
                        fullWidth
                        helperText={errors.password && errors.password.message}
                        inputRef={register}
                        label="Password"
                        name="password"
                        placeholder="Password"
                        required
                        type="password"
                        variant="outlined"
                      />
                      <TextField
                        className={classes.textField}
                        error={errors.confirm_password ? true : false}
                        fullWidth
                        helperText={
                          errors.confirm_password &&
                          errors.confirm_password.message
                        }
                        inputRef={register}
                        label="Confirm Password"
                        name="confirm_password"
                        placeholder="Confirm Password"
                        required
                        type="password"
                        variant="outlined"
                      />
                      <Button
                        className={classes.signUpButton}
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained">
                        Sign up now
                      </Button>
                      <Typography color="textSecondary" variant="body1">
                        Have an account?{' '}
                        <Link component={RouterLink} to="/sign-in" variant="h6">
                          Sign in
                        </Link>
                      </Typography>
                    </CardContent>
                  </Card>
                </form>
              </div>
            </div>
          </Grid>
        </Grid>
      </ReduxBlockUi>
    </div>
  );
};

SignUp.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignUp);
