import * as actionTypes from './AuthActions';

const initialState = {
  userObj: null,
  isAuthenticated: false
};

const AuthReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.REGISTER_USER:
      return {
        ...state,
        userObj: payload
      };
    case actionTypes.LOGIN:
      return {
        ...state,
        userObj: payload,
        isAuthenticated: true
      };
    // case actionTypes.LOGOUT:
    //   return {
    //     ...state,
    //     userObj: null,
    //     isAuthenticated: false
    //   };
    default:

      return state;
  }
};

export default AuthReducer;
