import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, CardHeader, CardActions, Typography, Button } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  header: {
    background: '#3f51b5',
    color: '#ffffff'
  },
  title: { 
    color: '#ffffff',
    // fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const Customer = props => {
  const { className, userInfo , ...rest} = props;
  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader       
        classes={{
          title: classes.title
        }}
        className={classes.header}
        title={userInfo.name}
      />
      <CardContent>
        <Typography
          color="textPrimary"
          component="p"
          variant="subtitle2"
        >
          Account #: { userInfo.uniqueId }<br/>
          Email: { userInfo.email }<br/>
          TIN: { userInfo.tin }<br/>
          ID #: { userInfo.idNumber }<br/>
          Home #: { userInfo.home }<br/>
          Mobile #: { userInfo.mobile }<br/>
          Office #: { userInfo.office }
        </Typography>
      </CardContent>
      <CardActions className={classes.actions}>
        <RouterLink to="/onlineCustomer" >
          <Button
            color="primary"
            size="small"
            variant="text"
          >
          Edit <ArrowRightIcon />
          </Button>
        </RouterLink>
        
      </CardActions>
    </Card>
  );
};

Customer.propTypes = {
  className: PropTypes.string,
  userInfo: PropTypes.object
};

export default Customer;
