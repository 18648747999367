import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { PaymentToolbar, PaymentTable } from './components';
import mockData from './data';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const PaymentHistory = () => {
  const classes = useStyles();
  
  const [filterString, setfilterString] = useState('')
  const [selectedStartDate, setStartDate] = useState(null)
  const [selectedEndDate, setEndDate] = useState(null)

  const handleFilterClick = () =>
  {
    let licenceSearchClause = '';
    if(selectedStartDate && selectedEndDate){
      let filterQuery = `${licenceSearchClause} Cast(entryDate as Date) between '${moment(selectedStartDate).format('MM/DD/YYYY')}' and '${moment(selectedEndDate).format('MM/DD/YYYY')}'`
      setfilterString(filterQuery)
    }
    
  }
  const handleResetClick = () =>
  {
    let filterQuery = ''
    setfilterString(filterQuery)
    setStartDate(null)
    setEndDate(null)
  }
  return (
    <div className={classes.root}>
      <PaymentToolbar
        onEndDateChange = {data=>setEndDate(data)}
        onFilterClick = {() => handleFilterClick()}
        onResetClick = {handleResetClick}
        onStartDateChange = {data=>setStartDate(data)}
        selectedEndDate = {selectedEndDate}
        selectedStartDate = {selectedStartDate}
      />
      <div className={classes.content}>
        <PaymentTable 
          filterQuery = {filterString}
        />
      </div>
    </div>
  );
};

export default PaymentHistory;
