import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import reduxMiddleware from 'react-block-ui/lib/reduxMiddleware';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import {
  loadingBarReducer,
  loadingBarMiddleware
} from 'react-redux-loading-bar';
import * as serviceWorker from './serviceWorker';
import reducer from './store/Reducer';
import onlineCustomerReducer from './store/OnlineCustomer/OnlineCustomerReducer';
import onlineVehicleReducer from './store/OnlineVehicle/OnlineVehicleReducer';
import requestReducer from './store/Request/RequestReducer';
import AuthReducer from './store/Auth/AuthReducer';
import generalReducer from './store/General/GeneralReducer'
//import roleReducer from './store/Role/RoleReducer'
import App from './App';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const appReducer = combineReducers({
  mainReducer:reducer,
  onlineCustomerReducer: onlineCustomerReducer,
  onlineVehicleReducer: onlineVehicleReducer,
  requestReducer: requestReducer,
  AuthReducer: AuthReducer,
  generalReducer: generalReducer,
  loadingBarReducer: loadingBarReducer,
  //roleReducer: roleReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action);
}

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk, loadingBarMiddleware(), reduxMiddleware)));



ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

serviceWorker.unregister();
