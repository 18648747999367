import React, { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { DropzoneArea } from 'material-ui-dropzone';
import {
  Button,
  TextField,
  makeStyles,
  Chip,
  CssBaseline,
  Grid,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Fab
} from '@material-ui/core';
import RootRef from '@material-ui/core/RootRef';
import { Container } from 'reactstrap';
import AtttachmentIcon from '@material-ui/icons/AttachFile';
import * as actionTypes from 'store/General/GeneralActions';
import ReduxBlockUi from 'react-block-ui/redux';
import * as yup from 'yup';
import NavigationIcon from '@material-ui/icons/Navigation';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const schema = yup.object().shape({
  licencePlateNumber: yup
    .string()
    .required(() => 'Licence Plate Number is required'),
  color: yup.string().required(() => 'Colour is required'),
  classificationId: yup
    .string()
    .trim()
    .required(() => 'Classification is required')
});

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  dropzoneArea: {
    minHeight: 0
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const ApproveRequestForm = props => {
  const {
    vehicle,
    classifications,
    onCloseModal,
    isModalOpen,
    pageTitle,
    handleOnApprovalSubmit,
    handleOnRejectSubmit,
    handleOnDownloadClick
  } = props;
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [fileId, setFileId] = useState();
  const classes = useStyles();
  const { register, handleSubmit, errors, setValue, getValues } = useForm({
    validationSchema: schema
  });

  const onLicenceChange = event => {
    setValue(
      'licencePlateNumber',
      event.target.value.toUpperCase().replace(/\s/g, '')
    );
  };

  //Form Submit
  const onSubmit = data => {
    if (vehicle) {
      data.id = vehicle.id;
    }
    data.file = file;
    data.fileId = fileId;
    data.fileName = fileName;
    //handleOnSubmit(data);
  };
  const onApproval = data => {
    var requestPayload = {
      id: vehicle.requestId,
      comments: data.comments,
      requestStatus: 'B9231F99-A51B-4C1B-B282-2F8337FC14BB',
      vehicle: {
        licencePlate: data.licencePlateNumber,
        color: data.color,
        classificationId: data.classificationId
      }
    };
    handleOnApprovalSubmit(requestPayload);
  };

  const onDecline = () => {
    const data = getValues();
    var requestPayload = {
      id: vehicle.requestId,
      comments: data["comments"],
      requestStatus: 'EB17A0A2-4441-4300-A731-50F49893D078'
      // vehicle : {
      //   licencePlate: data.licencePlateNumber,
      //   color: data.color,
      //   classificationId: data.classificationId,
      // }
    };
    handleOnRejectSubmit(requestPayload);
  };
  const handleEntered = () => {
    setValue('licencePlateNumber', '');
    setValue('color', '');
    //If props.selectedObject not null Fill state
    if (vehicle) {
      //Set Form to received object values
      setValue('licencePlateNumber', vehicle.licencePlate);
      setValue('color', vehicle.color);
    }
  };

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      onClose={onCloseModal}
      onEntered={() => handleEntered()}
      open={isModalOpen}>
      <ReduxBlockUi
        tag="div"
        block={actionTypes.BLOCK_INPUT}
        unblock={actionTypes.UNBLOCK_INPUT}
        onChange={() => {}}>
        <form noValidate>
          <DialogTitle id="form-dialog-title">{pageTitle}</DialogTitle>
          <CssBaseline />
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <TextField
                  error={errors.licencePlateNumber ? true : false}
                  fullWidth
                  helperText={
                    errors.licencePlateNumber &&
                    errors.licencePlateNumber.message
                  }
                  InputLabelProps={{ shrink: true }}
                  inputRef={register}
                  onChange={onLicenceChange}
                  label="Licence Plate Number"
                  name="licencePlateNumber"
                  placeholder="Licence Plate Number"
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  error={errors.color ? true : false}
                  fullWidth
                  helperText={errors.color && errors.color.message}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register}
                  label="Color"
                  name="color"
                  placeholder="Color"
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                  error={errors.classificationId ? true : false}>
                  <InputLabel htmlFor="classificationId" shrink>
                    Classification
                  </InputLabel>
                  <Select
                    native
                    // value={state.age}
                    // onChange={handleChange}
                    inputRef={register}
                    notched
                    label="Classification"
                    placeholder="Classification"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      name: 'classificationId',
                      id: 'classificationId'
                    }}>
                    <option aria-label="None" value=""> Select a Classification </option>
                    {classifications.sort((a,b) => (a.display > b.display) ? 1 : ((b.display > a.display) ? -1 : 0)).map(option => (
                      <option key={option.id} value={option.id}>
                        {option.display}
                      </option>
                    ))}
                  </Select>
                  <FormHelperText>
                    {errors.classificationId && errors.classificationId.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  error={errors.color ? true : false}
                  fullWidth
                  helperText={errors.color && errors.color.message}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register}
                  label="Comment"
                  name="comments"
                  placeholder="Comment"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  size="small"
                  startIcon={<CloudDownloadIcon />}
                  onClick = {handleOnDownloadClick}>
                  Download Registration
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={onCloseModal} variant="outlined">
              Cancel
            </Button>
            <Button
              color="error"
              type="button"
              variant="contained"
              onClick={() => onDecline()}>
              Reject
            </Button>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              onClick={handleSubmit(onApproval)}>
              Approve
            </Button>
          </DialogActions>
        </form>
      </ReduxBlockUi>
    </Dialog>
  );
};
export default ApproveRequestForm;
