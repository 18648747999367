import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import { DecodeJWTService } from 'helpers';

import {
  Dashboard as DashboardView,
  ProductList as ProductListView,
  TripHistory as TripHistoryView,
  PaymentHistory as PaymentHistoryView,
  VehicleManagement as VehicleManagementView,
  Typography as TypographyView,
  Icons as IconsView,
  Account as AccountView,
  Settings as SettingsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  OnlineCustomerView,
  OnlineVehicleView,
  ApprovedVehiclesView,
  RequestView,
  EmailConfirmation,
  ForgotPassword,
  ResetPassword,
  ResetPasswordConfirmation
} from './views';

const Routes = () => {
  // DecodeJWTService
  const decodeJWTService = DecodeJWTService.getService();
  return (
    <Switch>
      <Redirect exact from="/" to="/dashboard" />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={TripHistoryView}
        exact
        layout={MainLayout}
        path="/triphistory"
      />
      <RouteWithLayout
        component={PaymentHistoryView}
        exact
        layout={MainLayout}
        path="/paymenthistory"
      />
      <RouteWithLayout
        component={VehicleManagementView}
        exact
        layout={MainLayout}
        path="/vehicles"
      />
      <RouteWithLayout
        component={OnlineCustomerView}
        exact
        layout={MainLayout}
        path="/onlineCustomer"
      />
      <RouteWithLayout
        component={OnlineVehicleView}
        exact
        layout={MainLayout}
        path="/onlineVehicle"
      />
      {decodeJWTService.isAdmin() && (
        <RouteWithLayout
          component={RequestView}
          exact
          layout={MainLayout}
          path="/onlinerequests"
        />
      )}
      <RouteWithLayout
        component={ApprovedVehiclesView}
        exact
        layout={MainLayout}
        path="/approvedVehicles"
      />
      <RouteWithLayout
        component={ProductListView}
        exact
        layout={MainLayout}
        path="/products"
      />
      <RouteWithLayout
        component={TypographyView}
        exact
        layout={MainLayout}
        path="/typography"
      />
      <RouteWithLayout
        component={IconsView}
        exact
        layout={MainLayout}
        path="/icons"
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={EmailConfirmation}
        exact
        layout={MinimalLayout}
        path="/confirm-email"
      />
      <RouteWithLayout
        component={ForgotPassword}
        exact
        layout={MinimalLayout}
        path="/forgot-password"
      />
      <RouteWithLayout
        component={ResetPassword}
        exact
        layout={MinimalLayout}
        path="/password-reset"
      />
      <RouteWithLayout
        component={ResetPasswordConfirmation}
        exact
        layout={MinimalLayout}
        path="/reset-password-confirmation"
      />
      <RouteWithLayout
        component={ProductListView}
        exact
        layout={MinimalLayout}
        path="/product-list"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
