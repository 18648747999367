import React, { Fragment, useMemo, useState  } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {Header, VehicleToolbar} from './components';
import { colors, Tooltip, IconButton } from '@material-ui/core';
import { DataTable,Label } from 'components';
import * as actionTypes from 'store/OnlineVehicle/OnlineVehicleActions';
import CancelIcon from '@material-ui/icons/Close'


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  spacer: {
    flexGrow: 1
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }


}));

const ApprovedVehiclesView = () => {
  const classes = useStyles();
   
  const getTableDataUrl = '/Vehicle/getCustomerVerifiedVehicles';
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState();
  //Attach States

  const tableInstance = useSelector(state => state.onlineVehicleReducer.tableInstance)
  const refreshTable = useSelector(state => state.onlineVehicleReducer.refreshTable)
  


  //Attach Dispatch Actions
  
  const setTableSearch = searchText => dispatch(actionTypes.setTableSearch(searchText))
  const statusColors = {
    canceled: colors.grey[600],
    pending: colors.orange[600],
    approved: colors.green[600],
    deactivate: colors.red[600]
  };

  const columns = [

    {
      Header: 'UniqueId',
      accessor: 'uniqueId',
      sortType: 'basic',
      searchable: false
    },
    {
      Header: 'Licence #',
      accessor: 'licence',
      sortType: 'basic',
      searchable: true,

    },
    {
      Header: 'Colour',
      accessor: 'colour',
      sortType: 'basic',
      searchable: false,

    },
    {
      Header: 'Classification',
      accessor: 'classificationName',
      sortType: 'basic',
      searchable: false,

    },
    {
      Header: 'Status',
      accessor: '',
      disableSortBy:true,
      searchable: false,
      // eslint-disable-next-line
      Cell: cellInfo => {
        return (
          <Fragment>
            <Label
              color={statusColors.approved}
              variant="outlined"
            >
              Approved
            </Label>
          </Fragment>
        ) }
    }
    //,
    // {
    //   Header: 'Actions',
    //   accessor: '',
    //   disableSortBy:true,
    //   searchable: false,
    //   width: 45,
    //   // eslint-disable-next-line
    //   Cell: cellInfo => {
    //     return (
    //       <Fragment>
    //         <Tooltip
    //           placement="top"
    //           title="Deactivate"
    //         >
    //           <IconButton
    //             aria-label="deactivate"
    //             component="span"
    //             onClick={() => {
                  
    //             }}
    //             size="small"
    //           >
    //             <CancelIcon
    //               color="error"
    //             />
    //           </IconButton>
    //         </Tooltip>
    //       </Fragment>
    //     );
    //   }
    //}
  ];
  const sortColumns = useMemo(() => [{ id: 'uniqueId', desc: false }], [])
  const hiddenColumns = useMemo(() => ['uniqueId'])



 

  return (
    <Fragment>
      <div className={classes.root}>
        <Header />
        <div className={classes.content}>  
          <VehicleToolbar
            className={classes.searchInput}
            onSearchChange={(searchText) => setSearchText(searchText.target.value)}
          />
          <span className={classes.spacer} />
          
          <DataTable
            columns={columns}
            filterClause={tableInstance.filterClause}
            hiddenColumn={hiddenColumns}
            refreshTable={refreshTable}
            searchText={searchText}
            sortColumn={sortColumns}
            url={getTableDataUrl}
          />           
        </div>
      </div>
    </Fragment>
  );
};

export default ApprovedVehiclesView;





