import React, { useState, useEffect, Fragment } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Axios from 'axios/authAxios';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  FormHelperText,
  Checkbox,
  Paper,
  Typography
} from '@material-ui/core';
import * as actionTypes from '../../store/Auth/AuthActions';
import * as yup from 'yup';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';



const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  title: {
    paddingBottom: theme.spacing(2)
  },
  content: {
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  }
}));

const EmailConfirmation = props => {
  const { location, history } = props;

  const classes = useStyles();
  const [data, setData] = useState(null);

  useEffect(() => {
    const confirmEmail = async () => { 
      try{
        const result = await Axios.get('account/ConfrimEmail' + location.search);
        setData(result.data);
      }catch{
        setData({success: false, message: 'An unexpected error occurred, please contact support.'});
      }
      
    }
    confirmEmail();
  }, [])

  const message = () =>{
    if(data){
      if(data.success){
        return (
          <Alert severity="success">
            <AlertTitle>Email Confirmed</AlertTitle>
            <Typography
              component="p"
              variant="body1"
            >
        Thank you for confirming your email. Please click this {' '}  
              <Link
                component={RouterLink}
                to="/sign-in"
                variant="h6"
              >
          link
              </Link> to sign in.
            
            </Typography> 
          </Alert>
        )
      }
      else{
        return (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            <Typography
              component="p"
              variant="body1"
            >       
              {data.message}
            </Typography> 
          </Alert>
        )
      }
    }
    else{
      return (
        <Typography
          component="p"
          variant="body1"
        >       
          Loading...
        </Typography> 
      )
    }
  }
  return (
    <Fragment>
      <div className={classes.root}>
        <Card
          className={classes.content}
          raised
        >
          <CardContent />
        
      
         
          <Typography
            className={classes.title}
            component="h1"
            variant="h3"
          >
              Email Confirmation
          </Typography>
          <div>
            <Grid
              container
            >
              <Grid
                item
                md={12}
                xs={12}
              >
                {message()}
              </Grid>
            </Grid>
         
          </div>
          <CardContent />
        </Card> 
      </div>
    </Fragment>
  );
};

EmailConfirmation.propTypes = {
  history: PropTypes.object
};

export default withRouter(EmailConfirmation);
