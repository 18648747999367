import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useForm } from 'react-hook-form';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField
} from '@material-ui/core';
import * as actionTypes from 'store/Auth/AuthActions';
import * as yup from 'yup';
const schema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
      'Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
    ),
  confirm_password: yup
    .string()
    .required('Confirm Password is required')
    .oneOf([yup.ref('password')], 'Your passwords do not match.')
});
const useStyles = makeStyles(() => ({
  root: {}
}));

const Password = props => {
  const dispatch = useDispatch();
  const { className, ...rest } = props;

  const classes = useStyles();

  const { register, handleSubmit, errors, setValue } = useForm({
    validationSchema: schema
  });

  const [values, setValues] = useState({
    password: '',
    confirm: ''
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  //Form Submit
  const onSubmit = data => {
    dispatch(actionTypes.changePassword(data));
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form
        className={classes.form}
        noValidate
        onSubmit={handleSubmit(onSubmit)}>
        <CardHeader subheader="Update password" title="Password" />
        <Divider />
        <CardContent>
          <TextField
            className={classes.textField}
            error={errors.password ? true : false}
            fullWidth
            helperText={errors.password && errors.password.message}
            inputRef={register}
            label="Password"
            name="password"
            placeholder="Password"
            required
            type="password"
            variant="outlined"
          />
          <TextField
            className={classes.textField}
            error={errors.confirm_password ? true : false}
            fullWidth
            helperText={
              errors.confirm_password && errors.confirm_password.message
            }
            inputRef={register}
            label="Confirm Password"
            name="confirm_password"
            placeholder="Confirm Password"
            required
            style={{ marginTop: '1.5rem' }}
            type="password"
            variant="outlined"
          />
          {/* <TextField
            fullWidth
            label="Password"
            name="password"
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Confirm password"
            name="confirm"
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            type="password"
            value={values.confirm}
            variant="outlined"
          /> */}
        </CardContent>
        <Divider />
        <CardActions>
          <Button color="primary" variant="outlined" type="submit">
            Update
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
