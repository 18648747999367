import React, {useRef} from 'react';
import { Router } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import browserHistory from 'helpers/history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';
import IdleTimer from 'react-idle-timer'

import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
import * as actionTypes from './store/General/GeneralActions';
import * as authActionTypes from 'store/Auth/AuthActions';
import { Notifications } from './components';


Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

const App = () => {
  const dispatch = useDispatch();

  const alertOptions = useSelector(state => state.generalReducer.alertOptions)
  const isAuthenticated = useSelector(state => state.AuthReducer.isAuthenticated)
  const closeAlert = () => dispatch(actionTypes.closeAlertAction())
  const logout = () => dispatch(authActionTypes.logOutUser());
  const idleTimer = useRef(null);

  const onAction = (e) => {
  }

  const onActive = (e) => {
  }

  const onIdle = (e) => {
    if(isAuthenticated)
    {
      logout()
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <Router history={browserHistory}>
        <Routes />
        <Notifications
          closeAlert={closeAlert}
          isAlertOpen={alertOptions.showAlert}
          message={alertOptions.message}
          severity={alertOptions.severity}
        />
        <IdleTimer
          crossTab={{
            emitOnAllTabs: true
          }}
          debounce={250}
          element={document}
          onAction={(e) => onAction(e)}
          onActive={(e) => onActive(e)}
          onIdle={(e) => onIdle(e)}
          ref={ref => idleTimer.current = ref}
          timeout={1000 * 60 * 60}
        />

      </Router>
    </ThemeProvider>
  );
}
export default App;