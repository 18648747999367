import Axios from "../axios/axios";

function getTableData(
  url,
  page,
  pageSize,
  sorted,
  searchText,
  filterClause,
  columns,
  handleRetrievedData
) {
  let postObject = {
    _start: page,
    _length: pageSize,
    _sort: sorted.map((val) => {
      return { _column: val.id, _direction: val.desc ? "desc" : "asc" };
    }),
    _searchClause: searchText ?? "",
    _filterClause: filterClause ?? "",
    _columns: columns,
  };
  try {
    const data = Axios.post(url, postObject);

    if (handleRetrievedData) {
      return data
        .then((response) => handleRetrievedData(response))
        .catch((response) => {});
    } else {
      return {};
    }
  } catch (error) {
    console.error(error);
  }
}

//GET COLUMNS FOR FILTER
function getDataTableFields(columnData) {
  const fields = columnData
    .filter((val) => {
      return val.accessor != "";
    })
    .map((val) => {
      return {
        _name: val.accessor,
        _search: val.searchable,
      };
    });
  return [...new Set(fields)];
}

export { getTableData, getDataTableFields };
