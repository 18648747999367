import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import * as actionTypes from 'store/Actions';

import { UsersToolbar, VehicleFormModal, VehicleTable } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const VehicleManagement = () => {
  const [openModal, setOpenModal] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  //Attach States
  const requestedVehicles = useSelector(state => state.requestedVehicles);
  const selectedVehicle = useSelector(state => state.selectedRequestedVehicle);

  //Attach Dispatch Actions
  const saveRequestedVehicle = useCallback((url,data) => dispatch(actionTypes.saveRequestedVehicle(url,data)), [dispatch]);
  const setRequestedVehicle = useCallback((data) => dispatch(actionTypes.setRequestedVehicleAction(data)), [dispatch]);
  const fetchRequestedVehicles = useCallback((url,id) => dispatch(actionTypes.fetchRequestedVehicles(url,id)), [dispatch]);
  const fetchRequestedVehicle = useCallback((url,id) => dispatch(actionTypes.fetchRequestedVehicle(url,id)), [dispatch]);
  useEffect(() => {
    fetchRequestedVehicles('https://localhost:44327/api/vehicle/getOnlineVehicles?id=','dc84de72-9191-4bcd-8f42-4baf8151d8e4');
  }, [fetchRequestedVehicles])

  const onEdit = (id) => {
    setOpenModal(true);
    fetchRequestedVehicle('https://localhost:44327/api/vehicle/getOnlineVehicle?id=',id);
  }
  const handleClose = () =>{
    setOpenModal(false);
  }
  const onSave = (data) => {
    setOpenModal(false);
    setRequestedVehicle({uniqueId:selectedVehicle.uniqueId, licencePlateNumber:data.plate, colour:data.color});
    saveRequestedVehicle('https://localhost:44327/api/vehicle/saveOnlineVehicle',selectedVehicle);
  }

  return (
    <div className={classes.root}>
      <UsersToolbar />
      <VehicleFormModal
        handleClose={handleClose}
        onSave={onSave}
        openModal={openModal}
        selectedVehicle={selectedVehicle}
      />
      <div className={classes.content}>
        <VehicleTable
          onEdit={onEdit}
          users={requestedVehicles}
        />
      </div>
    </div>
  );
};

export default VehicleManagement;
