import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Header, General, Password } from './components';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import * as actionTypes from 'store/OnlineCustomer/OnlineCustomerActions';
import * as generalActionTypes from 'store/General/GeneralActions';
import ReduxBlockUi from 'react-block-ui/redux';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  }
}));

const OnlineCustomerView = () => {
  const classes = useStyles();

  const updateUrl = '/customer/updateOnlineCustomer';
  const getUrl = '/customer/getOnlineCustomer?id=';

  const dispatch = useDispatch();
  //Attach States
  const onlineCustomerObj = useSelector(
    state => state.onlineCustomerReducer.onlineCustomerObj
  );

  //Attach Dispatch Actions
  const updateSelectedOnlineCustomer = (updateUrl, onlineCustomerObj) =>
    dispatch(actionTypes.updateOnlineCustomer(updateUrl, onlineCustomerObj));

  useEffect(() => {
    dispatch(
      actionTypes.fetchOnlineCustomer(
        getUrl,
        'DC84DE72-9191-4BCD-8F42-4BAF8151D8E4'
      )
    );
  }, []);

  const handleSubmit = data => {
    if (data.uniqueId) {
      updateSelectedOnlineCustomer(updateUrl, data);
    }
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <Header />
      </div>
      <div className={classes.content}>
        <ReduxBlockUi
          tag="div"
          block={generalActionTypes.BLOCK_INPUT}
          unblock={generalActionTypes.UNBLOCK_INPUT}
          onChange={() => {}}>
          <Grid container spacing={4}>
            <Grid item md={7} xs={12}>
              <General customer={onlineCustomerObj} onSubmit={handleSubmit} />
            </Grid>
            <Grid item md={5} xs={12}>
              <Password />
            </Grid>
          </Grid>
        </ReduxBlockUi>
      </div>
    </Fragment>
  );
};

export default OnlineCustomerView;
