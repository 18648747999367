import * as actionTypes from './OnlineVehicleActions';

const initialState = {
  showOnlineVehicleModal: false,
  onlineVehicleObj: null,
  refreshTable: false,
  tableInstance: {
    searchText: '',
    filterClause: '',
    openDeleteConfirm: false,
    selectedId: null
  }
};

const onlineVehicleReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SHOW_ONLINEVEHICLE_MODAL:
      return {
        ...state,
        onlineVehicleObj: null,
        showOnlineVehicleModal: true
      };
    case actionTypes.CLOSE_ONLINEVEHICLE_MODAL:
      return {
        ...state,
        onlineVehicleObj: null,
        showOnlineVehicleModal: false
      };
    case actionTypes.SET_ONLINEVEHICLE:
      return {
        ...state,
        onlineVehicleObj: payload,
        showOnlineVehicleModal: true //opens modal when setting ONLINEVEHICLE
      };

    case actionTypes.DELETE_ONLINEVEHICLE:
      return {
        ...state,
        selectedId: null,
        tableInstance: { ...state.tableInstance, openDeleteConfirm: false }
      };

    case actionTypes.UPDATE_ONLINEVEHICLE:
    case actionTypes.SAVE_ONLINEVEHICLE:
      return {
        ...state,
        showOnlineVehicleModal: false,
        onlineVehicleObj: null,
        selectedId: null
      };
    case actionTypes.SET_SELECTED_ID:
      return {
        ...state,
        selectedId: payload
      };

    case actionTypes.SET_TABLE_SEARCH:
      return {
        ...state,
        tableInstance: { ...state.tableInstance, searchText: payload }
      };

    case actionTypes.SET_TABLE_FILTER:
      return {
        ...state,
        tableInstance: { ...state.tableInstance, filterClause: payload }
      };

    case actionTypes.REFRESH_TABLE:
      return {
        ...state,
        refreshTable: true
      };

    case actionTypes.REFRESH_TABLE_COMPLETE:
      return {
        ...state,
        refreshTable: false
      };

    case actionTypes.OPEN_TABLE_DELETE_CONFIRM:
      return {
        ...state,
        tableInstance: { ...state.tableInstance, openDeleteConfirm: true }
      };

    case actionTypes.CLOSE_TABLE_DELETE_CONFIRM:
      return {
        ...state,
        tableInstance: { ...state.tableInstance, openDeleteConfirm: false }
      };

    default:

      return state;
  }
};

export default onlineVehicleReducer;
