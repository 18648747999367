import React, { Fragment } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  header: {
    background: '#3f51b5',
    color: '#ffffff'
  },
  title: { 
    color: '#ffffff',
    // fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const Balance = props => {
  const { className, accountBalanceInfo, ...rest } = props;

  const classes = useStyles();
  // const balance = () => {
  //   if(user)
  //   return(
  //     <NumberFormat
  //       displayType={'text'}
  //       prefix={'$'}           
  //       thousandSeparator        
  //       value={accountBalanceInfo.balance}
  //     />
  //   )
  // }
  
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader      
        classes={{
          title: classes.title
        }}
        className={classes.header}
        title={
          <Fragment>
        Account Balance :
            <NumberFormat
              displayType={'text'}
              prefix={'$'}
              thousandSeparator
              value={accountBalanceInfo.balance}
            />
          </Fragment>
        }
      />
      <CardContent>
        <Typography
          color="textPrimary"
          component="p"
          variant="subtitle2"
        >
          Last Payment Date : {accountBalanceInfo.lastPaymentDate? moment(accountBalanceInfo.lastPaymentDate).format('DD/MM/YYYY') :'N/A'}<br/>
          Last Payment Amount : {
            <NumberFormat
              displayType={'text'}
              prefix={'$'}
              thousandSeparator
              value={accountBalanceInfo.lastPaymentAmount}
            />
          }
        </Typography>
      </CardContent>
    </Card>
  );
};

Balance.propTypes = {
  accountBalanceInfo: PropTypes.object,
  className: PropTypes.string
};

export default Balance;
