import React, { useEffect, useState } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Grid, Link } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useSelector, useDispatch } from 'react-redux';
import * as actionTypes from 'store/Actions';
import { Customer, Balance, LatestOrders } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [showBanner, setShowBanner] = useState(false);
  //Attach States
  const userInfo = useSelector(state => state.mainReducer.userInfo);
  const accountBalanceInfo = useSelector(
    state => state.mainReducer.accountBalanceInfo
  );
  const recentTrips = useSelector(state => state.mainReducer.recentTrips);

  useEffect(() => {
    dispatch(actionTypes.fetchDashboardInformation('/dashboard/getDashboard'));
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(userInfo).length > 0) {
      if (userInfo.mobile) {
        setShowBanner(false);
      } else {
        setShowBanner(true);
      }
    }
  }, [userInfo]);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        {showBanner && (
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Alert variant="filled" severity="warning">
              Attention!! Kindly update your &nbsp;
              <Link component={RouterLink} to="/onlineCustomer">
                Account Infomation
              </Link>{' '}
              &nbsp; to avoid any disruption with your Demerara Harbour Bridge
              Account.
            </Alert>
          </Grid>
        )}
        <Grid item lg={6} sm={6} xl={6} xs={6}>
          <Customer userInfo={userInfo} />
        </Grid>
        <Grid item lg={6} sm={6} xl={6} xs={6}>
          <Balance accountBalanceInfo={accountBalanceInfo} />
        </Grid>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <LatestOrders recentTrips={recentTrips} />
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(Dashboard);
