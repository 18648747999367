import React, { useState } from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TableSortLabel
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import NumberFormat from 'react-number-format';

import mockData from './data';
import { StatusBullet } from 'components';
import { tsImportEqualsDeclaration } from '@babel/types';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  button: {
    color: '#ffffff'
  }, 
  header: {
    background: '#3f51b5',
    color: '#ffffff'
  },
  title: { color: '#ffffff' },
  inner: {
    minWidth: 800
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const statusColors = {
  delivered: 'success',
  pending: 'info',
  refunded: 'danger'
};

const LatestOrders = props => {
  const { className, recentTrips, ...rest } = props;

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        action={
          <RouterLink to="/triphistory" >
            <Button
              className={classes.button}
              size="small"
              variant="text"
            >
          View all <ArrowRightIcon />
            </Button>
          </RouterLink>
        }
        classes={{
          title: classes.title
        }}
        className={classes.header}
        title="Recent Trips"
      />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sortDirection="desc">
                    <TableSortLabel
                      active
                      direction="desc"
                    >
                      Transaction Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Ticket #</TableCell>
                  <TableCell>Licence #</TableCell>
                  <TableCell>Classification</TableCell>
                  <TableCell>                      
                        Method
                  </TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recentTrips.sort(function(a, b){return b.tripId - a.tripId}).map(trip => (
                  <TableRow
                    hover
                    key={trip.tripId}
                  >
                    <TableCell>{moment(trip.transactionDate).format('DD/MM/YYYY | h:mm A')}</TableCell>
                    <TableCell>{trip.ticketNumber}</TableCell>
                    <TableCell>{trip.licence}</TableCell>
                    <TableCell>{trip.classification}</TableCell>
                    <TableCell> 
                      {trip.chargeType}
                    </TableCell>
                    <TableCell>
                      {
                        <NumberFormat
                          displayType={'text'}
                          prefix={'$'}
                          thousandSeparator
                          value={trip.amount}
                        />
                      }         
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <Divider />
    </Card>
  );
};

LatestOrders.propTypes = {
  className: PropTypes.string,
  recentTrips: PropTypes.array
};

export default LatestOrders;
