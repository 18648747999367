import React, { Fragment } from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
function Alert(props) {
  return <MuiAlert
    elevation={6}
    variant="filled"
    {...props}
  />;
}

const Notifications = ({ isAlertOpen, closeAlert, severity, message }) => {
  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={10000}
        key={'top,center'}
        onClose={closeAlert}
        open={isAlertOpen}
      >
        <Alert
          onClose={closeAlert}
          severity={severity}
        >
          {message}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default Notifications;
