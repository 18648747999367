import React, { Fragment, useMemo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
  Header,
  ApproveRequestForm,
  CustomerRequestForm,
  RequestToolbar
} from './components';
import { colors, Tooltip, IconButton, Typography } from '@material-ui/core';
import { DataTable, Label, DeleteConfirm } from 'components';
import moment from 'moment';
import * as actionTypes from 'store/Request/RequestActions';
import EditIcon from '@material-ui/icons/EditOutlined';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  spacer: {
    flexGrow: 1
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const RequestView = () => {
  const classes = useStyles();

  const pageTitle = 'Requests';
  const updateUrl = '/Request/updateRequest';
  const getUrl = '/Request/getRequest?id=';
  const getTableDataUrl = '/Request/getPendingRequests';
  const dispatch = useDispatch();
  //local state
  const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = useState(false);
  const [deleteObj, setDeleteObj] = useState();
  const [searchString, setSearchString] = useState();
  //Attach States

  const isModalOpen = useSelector(
    state => state.requestReducer.showRequestModal
  );
  const isCustomerModalOpen = useSelector(
    state => state.requestReducer.showCustomerRequestModal
  );
  const requestObj = useSelector(state => state.requestReducer.RequestObj);
  const classifications = useSelector(
    state => state.requestReducer.classificationTypes
  );

  const tableInstance = useSelector(
    state => state.requestReducer.tableInstance
  );
  const refreshTable = useSelector(state => state.requestReducer.refreshTable);

  //Attach Dispatch Actions
  const openModal = () => dispatch(actionTypes.showRequestModalAction());
  const closeModal = () => dispatch(actionTypes.closeRequestModalAction());

  const setTableSearch = searchText =>
    dispatch(actionTypes.setTableSearch(searchText));

  const updateSelectedRequest = (updateUrl, requestObj) =>
    dispatch(actionTypes.updateRequest(updateUrl, requestObj));
  const getSelectedRequest = (getUrl, id, type) =>
    dispatch(actionTypes.fetchRequest(getUrl, id, type));

    const downloadRegistration = (id) =>
    dispatch(actionTypes.downloadRegistration(id));
  useEffect(() => {
    dispatch(actionTypes.getClassifications('/ClassificationType/getListing'));
  }, [dispatch]);

  const statusColors = {
    canceled: colors.grey[600],
    pending: colors.orange[600],
    approved: colors.green[600],
    deactivate: colors.red[600]
  };

  const columns = [
    {
      Header: 'UniqueId',
      accessor: 'uniqueId',
      sortType: 'basic',
      searchable: false
    },
    {
      Header: 'Date Requested',
      accessor: 'dateRequested',
      sortType: 'basic',
      searchable: false,
      Cell: cellInfo => {
        return (
          <Fragment>
            {moment(cellInfo.row.original.dateRequested).format(
              'DD/MM/YYYY | h:mm A'
            )}
          </Fragment>
        );
      }
    },
    {
      Header: 'Request',
      accessor: 'title',
      sortType: 'basic',
      searchable: true
    },
    {
      Header: 'Requestor',
      accessor: 'requestor',
      sortType: 'basic',
      searchable: true
    },
    {
      Header: 'Status',
      accessor: '',
      disableSortBy: true,
      searchable: false,
      // eslint-disable-next-line
      Cell: cellInfo => {
        return (
          <Fragment>
            <Label color={statusColors.pending} variant="outlined">
              Pending
            </Label>
          </Fragment>
        );
      }
    },
    {
      Header: 'Actions',
      accessor: '',
      searchable: false,
      disableSortBy: true,
      width: 45,
      // eslint-disable-next-line
      Cell: cellInfo => {
        return (
          <Fragment>
            <Tooltip placement="top" title="Approve/Decline">
              <IconButton
                aria-label="edit"
                component="span"
                onClick={() => {
                  action(
                    cellInfo.row.original.uniqueId,
                    cellInfo.row.original.requestType,
                    'Edit'
                  );
                }}
                size="small">
                <ViewHeadlineIcon />
              </IconButton>
            </Tooltip>
          </Fragment>
        );
      }
    }
  ];
  const sortColumns = useMemo(() => [{ id: 'dateRequested', desc: true }], []);
  const hiddenColumns = useMemo(() => ['uniqueId']);

  function action(id, type, action) {
    switch (action) {
      case 'Edit':
        getSelectedRequest(getUrl, id, type);
        break;
      default:
        break;
    }
  }

  const newOnlineVehicle = () => {
    openModal();
  };

  const handleApprovalSubmit = data => {
    updateSelectedRequest(updateUrl, data);
  };
  const handleRejectionSubmit = data => {
    setDeleteObj(data);
    setOpenDeleteConfirmModal(true);
    //updateSelectedRequest(updateUrl, data);
  };

  const handleConfirmDelete = () => {
    setOpenDeleteConfirmModal(false);
    updateSelectedRequest(updateUrl, deleteObj);
  };
  const handleDownloadClick = () => {
    downloadRegistration(requestObj?.id);
  };
  const deleteConfirmContent = (
    <Typography variant="body1" component="p">
      Are you sure you want to reject this request?
    </Typography>
  );

  return (
    <Fragment>
      <div className={classes.root}>
        <Header newButtonClick={newOnlineVehicle} />
        <div className={classes.content}>
          <RequestToolbar
            className={classes.searchInput}
            onSearchChange={searchText =>
              setSearchString(searchText.target.value)
            }
          />
          <span className={classes.spacer} />

          <DataTable
            columns={columns}
            filterClause={tableInstance.filterClause}
            hiddenColumn={hiddenColumns}
            refreshTable={refreshTable}
            searchText={searchString}
            sortColumn={sortColumns}
            url={getTableDataUrl}
          />

          <ApproveRequestForm
            handleOnApprovalSubmit={handleApprovalSubmit}
            handleOnRejectSubmit={handleRejectionSubmit}
            handleOnDownloadClick={handleDownloadClick}
            isModalOpen={isModalOpen}
            onCloseModal={closeModal}
            pageTitle={'Vehicle Request'}
            vehicle={requestObj}
            classifications={classifications}
          />
          <CustomerRequestForm
            handleOnApprovalSubmit={handleApprovalSubmit}
            handleOnRejectSubmit={handleRejectionSubmit}
            isModalOpen={isCustomerModalOpen}
            onCloseModal={closeModal}
            pageTitle={'Customer Request'}
            Customer={requestObj}
          />
          <DeleteConfirm
            pageTitle="Reject Confirmation"
            isModalOpen={openDeleteConfirmModal}
            onCloseModal={() => setOpenDeleteConfirmModal(false)}
            onProceed={() => handleConfirmDelete()}>
            {deleteConfirmContent}
          </DeleteConfirm>
        </div>
      </div>
    </Fragment>
  );
};

export default RequestView;
