import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import * as yup from 'yup';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Divider,
  TextField,
  colors
} from '@material-ui/core';

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  // taxIdentificationNumber: yup.string().required('Tax Identification Number is required'),
  // identificationNumber: yup.string().required('Identification Number is required'),
  mobileNumber: yup.string().required('Mobile Number is required'),
  // homeNumber: yup.string().required('Home Number is required')
});

const useStyles = makeStyles(theme => ({
  root: {},
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));

const GeneralSettings = props => {
  const { className, customer, ...rest } = props;

  const classes = useStyles();
  const { register, handleSubmit, errors, setValue } = useForm({
    validationSchema: schema
  });

  // const [values, setValues] = useState(profile);
  useEffect(() => {
    //Initialize Form
    setValue( 'name', '');
    setValue( 'taxIdentificationNumber', '');
    setValue( 'identificationNumber', '');
    setValue( 'mobileNumber', '');
    setValue( 'homeNumber', '');
    setValue( 'officeNumber', '');

    //If props.selectedObject not null Fill state
    if (customer) {    
      //Set Form to received object values
      setValue( 'name', customer.name);
      setValue( 'taxIdentificationNumber', customer.taxIdentificationNumber);
      setValue( 'identificationNumber', customer.identificationNumber);
      setValue( 'mobileNumber', customer.mobileNumber);
      setValue( 'homeNumber', customer.homeNumber);
      setValue( 'officeNumber', customer.officeNumber);
    }
  }, [customer, setValue]);
  
  //Form Submit
  const onSubmit = data => {
    if (customer) {
      data.uniqueId = customer.uniqueId;
    }
    props.onSubmit(data);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form 
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >

        <CardHeader 
          subheader="Update profile"
          title="Profile"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                error={errors.name ? true: false}
                fullWidth
                helperText={errors.name && errors.name.message}
                InputLabelProps={{ shrink: true }}
                inputRef={register}
                label="Name"
                name="name"
                placeholder="Name"
                required
                variant="outlined"
              />
              <TextField
                error={errors.taxIdentificationNumber ? true: false}
                fullWidth
                helperText={errors.taxIdentificationNumber && errors.taxIdentificationNumber.message}
                InputLabelProps={{ shrink: true }}
                inputRef={register}
                label="Tax Identification Number"
                name="taxIdentificationNumber"
                placeholder="Tax Identification Number"
                //required
                style={{ marginTop: '1.5rem' }}
                variant="outlined"
              />
              <TextField
                error={errors.identificationNumber ? true: false}
                fullWidth
                helperText={errors.identificationNumber && errors.identificationNumber.message}
                InputLabelProps={{ shrink: true }}
                inputRef={register}
                label="Identification Number"
                name="identificationNumber"
                placeholder="Identification Number"
                //required
                style={{ marginTop: '1.5rem' }}
                variant="outlined"
              />
            </Grid>
            
            <Grid
              item
              md={6}
              xs={12}
            >
              
              <TextField
                error={errors.homeNumber ? true: false}
                fullWidth
                helperText={errors.homeNumber && errors.homeNumber.message}
                InputLabelProps={{ shrink: true }}
                inputRef={register}
                label="Home Number"
                name="homeNumber"
                placeholder="Home Number"
                //required
                variant="outlined"
              />
              <TextField
                error={errors.mobileNumber ? true: false}
                fullWidth
                helperText={errors.mobileNumber && errors.mobileNumber.message}
                InputLabelProps={{ shrink: true }}
                inputRef={register}
                label="Mobile Number"
                name="mobileNumber"
                placeholder="Mobile Number"
                required
                style={{ marginTop: '1.5rem' }}
                variant="outlined"
              />
              <TextField
                error={errors.officeNumber ? true: false}
                fullWidth
                helperText={errors.officeNumber && errors.officeNumber.message}
                InputLabelProps={{ shrink: true }}
                inputRef={register}
                label="Office Number"
                name="officeNumber"
                placeholder="Office Number"
                style={{ marginTop: '1.5rem' }}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            type="submit"
            variant="outlined"
          >
            Submit Changes
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

GeneralSettings.propTypes = {
  className: PropTypes.string
};

export default GeneralSettings;
