import axios from 'axios';
import history from 'helpers/history';
import authAxios from './authAxios'
import {LocalStorageService} from 'helpers';
//import router from './router/router';
const instance = axios.create({
  baseURL: 'https://dhbcinternalapi.ssi.gy/harbourbridgeportalapitest/api'
});

// LocalstorageService
const localStorageService = LocalStorageService.getService();

// Add a request interceptor
instance.interceptors.request.use(
  config => {
    const token = localStorageService.getAccessToken();
    
    if (token && token!=='undefined') {

      config.headers['Authorization'] = 'Bearer ' + token;
    }else{
      history.push('/sign-in');
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  error => {
    Promise.reject(error)
  });



//Add a response interceptor

instance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if (error.response.status === 401 && originalRequest.url === 'https://dhbcidentitytest.ssi.gy/api/account/RefreshToken') {
    history.push('/sign-in');
    return Promise.reject(error);
  }

  if (error.response.status === 401 && !originalRequest._retry) {

    originalRequest._retry = true;
    const refreshToken = localStorageService.getRefreshToken();
    const res = await authAxios.post('/account/RefreshToken', {
      'token': refreshToken
    });
    if (res.status === 401) {
      history.push('/sign-in');
      return Promise.reject(error);
    }
    if (res.status === 200 && res.data.success) {
      res.data.data.expiration_date = new Date(new Date().getTime() + res.data.data.expires_in * 1000);
      localStorageService.setToken(res.data.data);
      instance.defaults.headers.common['Authorization'] = 'Bearer ' + localStorageService.getAccessToken();
      return instance(originalRequest);
    }
  }
  return Promise.reject(error);
});

export default instance;
