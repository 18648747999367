import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Avatar,
  Typography,
  Divider,
  Button,
  Grid,
  IconButton
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';

const useStyles = makeStyles(theme => ({
  root: {},
  details: {
    display: 'flex',
    position: 'relative',
    '& .pro-edit': {
      position: 'absolute',
      right: 0,
      top: 0
    }
  },
  detail: {
    marginLeft: theme.spacing(2),
    borderRight: '2px dashed #ccc'
  },
  personalInfo:{
    listStyle: 'none',
    marginBottom: 0,
    padding: 0,
    '& li':{
      marginBottom: theme.spacing(2),
      '& .title':{
        color: '#4f4f4f',
        float: 'left',
        fontFamily: 'CircularStd, sans-serif',
        fontSize: '.9375rem',
        fontWeight: 'bold',
        marginRight:  theme.spacing(3),
        width: '25%'
      },
      '& .text':{
        color: '#8e8e8e',
        display: 'block',
        overflow: 'hidden',
        fontFamily: 'CircularStd, sans-serif',
        fontSize: '.9375rem',
        fontWeight: 'bold',
      }
    }
  },
  avatar: {
    height: 120,
    width: 120,
    flexShrink: 0,
    flexGrow: 0
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  }
}));

const BasicInfoCard = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const user = {
    name: 'Shen Zhi',
    city: 'Los Angeles',
    country: 'USA',
    timezone: 'GTM-7',
    avatar: '/images/avatars/avatar_11.png'
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >          
      <CardContent>
        <div className={classes.details}> 
          <Avatar
            className={classes.avatar}
            src={user.avatar}
          />
          <Grid
            container
            spacing={3}
          >
          
            <Grid
              item
              lg={5}
              md={5}
              xs={5}
            >
            
              <div className={classes.detail}>
                <Typography
                  variant="h3"
                >
              John Doe
                </Typography>
                <Typography
                  gutterBottom
                  variant="h6"
                >
              UI/UX Design Team
                </Typography>
                <Typography>
              Web Designer
                </Typography>
                <Typography
                  variant="h6"
                >
              Employee ID : FT-0001
                </Typography>
                <Typography
                  className={classes.dateText}
                  color="textSecondary"
                  variant="body1"
                >
              Date of Employment: {moment().format('Do MMMM YYYY')}
                </Typography>
              </div>
           
            </Grid> 
            <Grid
              item
              lg={7}
              md={7}
              xs={7}
              
            >
              <ul className={classes.personalInfo}>
                <li>
                  <Typography
                    className="title"
                    variant="h6"
                  >
              Phone:
                  </Typography>
                  <Typography
                    className="text"
                    variant="h6"
                  >
              9876543210
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="title"
                    variant="h6"
                  >
              Email:
                  </Typography>
                  <Typography
                    className="text"
                    variant="h6"
                  >
              johndoe@example.com
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="title"
                    variant="h6"
                  >
              Date of Birth:
                  </Typography>
                  <Typography
                    className="text"
                    variant="h6"
                  >
                    {moment().format('Do MMMM YYYY')}
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="title"
                    variant="h6"
                  >
              Address:
                  </Typography>
                  <Typography
                    className="text"
                    variant="h6"
                  >
              1861 Bayonne Ave, Manchester Township, NJ, 08759
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="title"
                    variant="h6"
                  >
              Gender:
                  </Typography>
                  <Typography
                    className="text"
                    variant="h6"
                  >
              Male
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Grid> 
          <div className="pro-edit">
            <IconButton
              aria-label="edit"
            >
              <EditIcon />
            </IconButton>
          </div>
        </div>
        

         
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          className={classes.uploadButton}
          color="primary"
          variant="text"
        >
          Upload picture
        </Button>
        <Button variant="text">Remove picture</Button>
      </CardActions>
    </Card>
  );
};

BasicInfoCard.propTypes = {
  className: PropTypes.string
};

export default BasicInfoCard;

