import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from "react";
import { useTable, useFlexLayout, usePagination, useSortBy } from "react-table";
import { getTableData } from "../../helpers/dataProvider";
import { PaginationActions } from "./components";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TablePagination,
  TableSortLabel,
  CircularProgress,
  Card,
  CardActions,
  CardContent,
} from "@material-ui/core";

//import classes from './DataTable.css'
const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  datatable: {
    marginTop: theme.spacing(2),
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  pagination: {
    justifyContent: "flex-end",
  },
}));

const useIsMounted = () => {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);
  return isMounted;
};

const DataTable = (props) => {
  const isMounted = useIsMounted();

  // We'll start our table without any data
  const {
    className,
    columns,
    searchText,
    filterClause,
    sortColumn = [],
    hiddenColumn = [],
    url,
    refreshTable,
    // deleteConfirmContent,
    // isDeleteConfirmOpen,
    // onDeleteProceed,
    // onCloseDeleteModal,
    ...rest
  } = props;

  const classes = useStyles();
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = useRef(0);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        sortBy: sortColumn,
        hiddenColumns: hiddenColumn,
      }, // Pass our hoisted table state
      manualSortBy: true,
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.

      pageCount: pageCount,
    },
    useFlexLayout,
    useSortBy,
    usePagination
  );

  //FUNCTION TO FETCH DATA
  const fetchedData = useCallback(
    (pageSize, pageIndex) => {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;
      tableInstance.setPageSize(pageSize);

      // Set the loading state
      setLoading(true);
      if (fetchId === fetchIdRef.current) {
        //gets unique column data fields
        const fields = getDataTableFields(columns);
        //calls server for table data
        getTableData(
          url,
          pageIndex * pageSize,
          pageSize,
          tableInstance.state.sortBy.length
            ? tableInstance.state.sortBy
            : sortColumn,
          searchText,
          filterClause,
          fields,
          (res) => {
            if (isMounted.current) {
              let serverData = res.data.data;
              let totalRecords = res.data.recordsTotal;
              setTotalRecords(totalRecords);

              setData(serverData);

              // Your server could send back total page count.
              setPageCount(Math.ceil(totalRecords / pageSize));

              setLoading(false);
            }
          }
        );
      }

      // We'll even set a delay to simulate a server here
    },
    [
      columns,
      //searchText,
      filterClause,
      tableInstance.state.sortBy,
      sortColumn,
      tableInstance,
      url,
      isMounted,
    ]
  );

  useEffect(() => {
    const index = tableInstance.state.pageIndex;
    const size = tableInstance.state.pageSize;
    fetchedData(size, index);
  }, [
    tableInstance.state.pageIndex,
    tableInstance.state.pageSize,
    tableInstance.state.sortBy,
    sortColumn
  ]);

  useEffect(() => {
    const index = tableInstance.state.pageIndex;
    const size = tableInstance.state.pageSize;
    if (refreshTable) fetchedData(size, index);
  }, [
    tableInstance.state.pageIndex,
    tableInstance.state.pageSize,
    refreshTable,
  ]);

  useEffect(() => {
    tableInstance.gotoPage(0);
    const index = tableInstance.state.pageIndex;
    const size = tableInstance.state.pageSize;
    fetchedData(size, index);
  }, [
      searchText,
      filterClause
  ]);

  const getSortIcon = (column) => {
    if (column.isSorted) {
      if (!column.isSortedDesc) {
        return (
          <TableSortLabel active direction="asc">
            {column.Header}
          </TableSortLabel>
        );
      } else {
        return (
          <TableSortLabel active direction="desc">
            {column.Header}
          </TableSortLabel>
        );
      }
    } else {
      return column.Header;
    }
  };
  const getColumnHeaders = column => {
    
    if (column.canSort) {
      return (
        <span {...column.getSortByToggleProps()}>
          {getSortIcon(column)}                              
        </span>
      )
    } else {
      return column.Header
    }
  }
  //GET COLUMNS FOR FILTER
  function getDataTableFields(columnData) {
    const fields = columnData
      .filter((val) => {
        return val.accessor != "";
      })
      .map((val) => {
        return {
          _name: val.accessor,
          _search: val.searchable,
        };
      });
    return [...new Set(fields)];
  }

  const handleChangePage = (event, newPage) => {
    tableInstance.gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    tableInstance.setPageSize(Number(event.target.value));
    tableInstance.gotoPage(0);
  };

  const getTableLoader = () => {
    return loading ? (
      <TableRow>
        <TableCell colSpan="10000">
          <CircularProgress style={{ left: "50%", position: "relative" }} />
        </TableCell>
      </TableRow>
    ) : null;
  };

  const getNoDataDisplay = () => {
    return !data.length && !loading ? (
      <TableRow>
        <TableCell
          style={{ left: "50%", position: "relative" }}
          colSpan="10000"
        >
          No data to display
        </TableCell>
      </TableRow>
    ) : null;
  };

  // Render the UI for your table
  return (
    <Fragment>
      <Card
        {...rest}
        className={clsx(classes.root, classes.datatable, className)}
      >
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table {...tableInstance.getTableProps()}>
                <TableHead>
                  {tableInstance.headerGroups.map((headerGroup) => (
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => {
                        return (
                          <TableCell
                            {...column.getHeaderProps()}

                            // onClick={() => sort(column)}
                            // {...column.getHeaderProps()}
                          >
                            {getColumnHeaders(column)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody {...tableInstance.getTableBodyProps()}>
                  {tableInstance.page.map((row) => {
                    tableInstance.prepareRow(row);
                    return (
                      <TableRow {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <TableCell {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                  {getTableLoader()}
                  {getNoDataDisplay()}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.pagination}>
          <TablePagination
            ActionsComponent={PaginationActions}
            component="div"
            count={totalRecords}
            onChangePage={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={tableInstance.state.pageIndex}
            rowsPerPage={tableInstance.state.pageSize}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
          />
        </CardActions>
      </Card>
    </Fragment>
  );
};

export default DataTable;
