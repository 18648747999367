import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { TripToolbar, TripTable } from './components';
import mockData from './data';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const TripHistory = () => {
  const classes = useStyles();
  
  const [filterString, setfilterString] = useState('')
  const [licenceNumber, setLicenceNumber] = useState('')
  const [selectedStartDate, setStartDate] = useState(null)
  const [selectedEndDate, setEndDate] = useState(null)

  const handleFilterClick = () =>
  {
    let licenceSearchClause = '';
    if(licenceNumber){
      licenceSearchClause = `licence like '%${licenceNumber}%' and`
    }
    if(selectedStartDate && selectedEndDate){
      let filterQuery = `${licenceSearchClause} Cast(transactionDate as Date) between '${moment(selectedStartDate).format('MM/DD/YYYY')}' and '${moment(selectedEndDate).format('MM/DD/YYYY')}'`
      setfilterString(filterQuery)
    }else{
      setfilterString(`licence like '%${licenceNumber}%'`)
    }
  }
  const handleResetClick = () =>
  {
    let filterQuery = ''
    setfilterString(filterQuery)
    setLicenceNumber('')
    setStartDate(null)
    setEndDate(null)
  }
  return (
    <div className={classes.root}>
      <TripToolbar
        licenceNumber = {licenceNumber}
        onEndDateChange = {data=>setEndDate(data)}
        onFilterClick = {() => handleFilterClick()}
        onLicenceNumberChange = {data=>setLicenceNumber(data.target.value)}
        onResetClick = {handleResetClick}
        onStartDateChange = {data=>setStartDate(data)}
        selectedEndDate = {selectedEndDate}
        selectedStartDate = {selectedStartDate}
      />
      <div className={classes.content}>
        <TripTable 
          filterQuery = {filterString}
        />
      </div>
    </div>
  );
};

export default TripHistory;
