import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  IconButton
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';

const useStyles = makeStyles(theme => ({
  root: {},
  details: {
    display: 'flex',
    position: 'relative',
    '& .pro-edit': {
      position: 'absolute',
      right: 0,
      top: 0
    }
  },
  detail: {
    marginLeft: theme.spacing(2),
    borderRight: '2px dashed #ccc'
  },
  personalInfo:{
    listStyle: 'none',
    marginBottom: 0,
    padding: 0,
    '& li':{
      marginBottom: theme.spacing(2),
      '& .title':{
        color: '#4f4f4f',
        float: 'left',
        fontFamily: 'CircularStd, sans-serif',
        fontSize: '.9375rem',
        fontWeight: 'bold',
        marginRight:  theme.spacing(3),
        width: '25%'
      },
      '& .text':{
        color: '#8e8e8e',
        display: 'block',
        overflow: 'hidden',
        fontFamily: 'CircularStd, sans-serif',
        fontSize: '.9375rem',
        fontWeight: 'bold',
      }
    }
  },
  avatar: {
    height: 120,
    width: 120,
    flexShrink: 0,
    flexGrow: 0
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  }
}));

const PersonalInfoCard = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const user = {
    name: 'Shen Zhi',
    city: 'Los Angeles',
    country: 'USA',
    timezone: 'GTM-7',
    avatar: '/images/avatars/avatar_11.png'
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >     
      <CardHeader
        action={
          <IconButton
            aria-label="edit"
          >
            <EditIcon />
          </IconButton>
        }
        title={
          <Typography
            variant="h4"
          >
              Personal Information
          </Typography>
        }

      /> 
      <CardContent>
        <div className={classes.details}>      
           
          <Grid
            item
            lg={12}
            md={12}
            xs={12}              
          >            
            <ul className={classes.personalInfo}>
              <li>
                <Typography
                  className="title"
                  variant="h6"
                >
              Phone:
                </Typography>
                <Typography
                  className="text"
                  variant="h6"
                >
              9876543210
                </Typography>
              </li>
              <li>
                <Typography
                  className="title"
                  variant="h6"
                >
              Email:
                </Typography>
                <Typography
                  className="text"
                  variant="h6"
                >
              johndoe@example.com
                </Typography>
              </li>
              <li>
                <Typography
                  className="title"
                  variant="h6"
                >
              Date of Birth:
                </Typography>
                <Typography
                  className="text"
                  variant="h6"
                >
                  {moment().format('Do MMMM YYYY')}
                </Typography>
              </li>
              <li>
                <Typography
                  className="title"
                  variant="h6"
                >
              Address:
                </Typography>
                <Typography
                  className="text"
                  variant="h6"
                >
              1861 Bayonne Ave, Manchester Township, NJ, 08759
                </Typography>
              </li>
              <li>
                <Typography
                  className="title"
                  variant="h6"
                >
              Gender:
                </Typography>
                <Typography
                  className="text"
                  variant="h6"
                >
              Male
                </Typography>
              </li>
            </ul>
          </Grid>
        </div>
        

         
      </CardContent>
    </Card>
  );
};

PersonalInfoCard.propTypes = {
  className: PropTypes.string
};

export default PersonalInfoCard;

