import * as actionTypes from './Actions';

const initialState = {
  showUserModal: false,
  showUserAlert: false,
  userObj: null,
  userInfo: {},
  accountBalanceInfo: {
    lastPaymentAcount: 0,
    lastPaymentDate: 'N/A',
    thresholdAmount: 0,
    receipt: 'N/A',
    balance: 0
  },
  recentTrips: [],
  requestedVehicles: [],
  selectedRequestedVehicle: {},
  userResponseMessage: {}
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SHOW_USER_MODAL:
      return {
        ...state,
        userObj: {},
        showUserModal: true
      };
    case actionTypes.CLOSE_USER_MODAL:
      return {
        ...state,
        showUserModal: false
      };
    case actionTypes.SHOW_USER_ALERT:
      return {
        ...state,
        showUserAlert: true
      };
    case actionTypes.CLOSE_USER_ALERT:
      return {
        ...state,
        showUserAlert: false
      };
    case actionTypes.SET_USER:
      return {
        ...state,
        userResponseMessage: {},
        userObj: payload,
        showUserModal: true //opens modal when setting user
      };

    case actionTypes.FETCH_USER_FAILED:
      return {
        ...state,
        userObj: null,
        userResponseMessage: payload,
        showUserAlert: true
      };

    case actionTypes.DELETE_USER:
      return {
        ...state,
        userResponseMessage: payload,
        showUserAlert: true //opens alert when setting user
      };

    case actionTypes.SAVE_USER_FAILED:
      return {
        ...state,
        userObj: null,
        userResponseMessage: payload,
        showUserAlert: true
      };

    case actionTypes.SAVE_USER:
      return {
        ...state,
        userResponseMessage: payload,
        showUserModal: false,
        showUserAlert: true, //opens alert when setting user
        userObj: null
      };
    case actionTypes.GET_USER_INFO:
      return {
        ...state,
        userInfo: payload
      };
    case actionTypes.GET_ACCOUNT_BALANCE_INFO:
      if(payload !== null){
        return {
          ...state,
          accountBalanceInfo: payload 
        };
      }else{
        return state;
      }
      
    case actionTypes.GET_RECENT_TRIPS:
      return {
        ...state,
        recentTrips: payload?payload:[]
      };
    case actionTypes.SET_REQUESTED_VEHICLES:
      return {
        ...state,
        requestedVehicles: payload?payload:[]
      };
    case actionTypes.GET_REQUESTED_VEHICLES_FAILED:
      return {
        ...state,
        requestedVehicles: []
      };
    case actionTypes.SET_REQUESTED_VEHICLE:
      return {
        ...state,
        selectedRequestedVehicle: payload?payload:null
      };
    case actionTypes.GET_REQUESTED_VEHICLE_FAILED:
      return {
        ...state,
        selectedRequestedVehicle: []
      };


    default:

      return state;
  }
};

export default reducer;
