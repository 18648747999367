import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from '@material-ui/core';

const Modal = props => {
  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      onClose={props.onCloseModal}
      open={props.isModalOpen}
    >
      <DialogTitle id="form-dialog-title">{props.pageTitle}</DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={props.onCloseModal}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={props.onSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
