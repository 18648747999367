import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from '@material-ui/core';
import * as actionTypes from 'store/General/GeneralActions';
import ReduxBlockUi from 'react-block-ui/redux';

const DeleteConfirm = props => {
  return (
    <Dialog
      open={props.isModalOpen}
      onClose={props.onCloseModal}
      aria-labelledby="form-dialog-title">
      <ReduxBlockUi
        tag="div"
        block={actionTypes.BLOCK_INPUT}
        unblock={actionTypes.UNBLOCK_INPUT}
        onChange={() => {}}>
        <DialogTitle id="form-dialog-title">
          {props.pageTitle ?? 'Confirm Delete'}
        </DialogTitle>
        <DialogContent>{props.children}</DialogContent>
        <DialogActions>
          <Button onClick={props.onProceed} color="primary">
            Proceed
          </Button>
          <Button onClick={props.onCloseModal} color="error">
            Cancel
          </Button>
        </DialogActions>
      </ReduxBlockUi>
    </Dialog>
  );
};

export default DeleteConfirm;
