import jwt_decode from "jwt-decode";
import { LocalStorageService } from 'helpers';

// LocalstorageService
const localStorageService = LocalStorageService.getService();

const DecodeJWTService = (function(){
  var _service;
  function _getService() {
    if(!_service) {
      _service = this;
      return _service
    }
    return _service
  }
  //const token = localStorageService.getAccessToken();
  function _getUserRole() {
    const token = localStorageService.getAccessToken();
    if(token){
      return jwt_decode(token)?.role;  
    }else{
      return '';
    }
  }
  function _isAdmin() {
    const token = localStorageService.getAccessToken();    
    var isAdmin = false;
    if(token){
      if (jwt_decode(token)?.role === 'PortalAdmin'){
        isAdmin = true;
      }
    }
    
    return isAdmin;  
  }
  
  return {
    getService : _getService,
    getUserRole : _getUserRole,
    isAdmin : _isAdmin
  }
})();
export default DecodeJWTService;