export const SHOW_ALERT = 'SHOW_ALERT'
export const SHOW_SUCCESS_ALERT = 'SHOW_SUCCESS_ALERT'
export const SHOW_ERROR_ALERT = 'SHOW_ERROR_ALERT'
export const CLOSE_ALERT = 'CLOSE_ALERT'

export const BLOCK_INPUT = 'BLOCK_INPUT'
export const UNBLOCK_INPUT = 'UNBLOCK_INPUT'

export const blockInputAction = () => ({ type: BLOCK_INPUT });
export const unblockInputAction = () => ({ type: UNBLOCK_INPUT });

//ALERT ACTIONS
export const showAlertAction = payload => ({
  type: SHOW_ALERT,
  payload: payload
})
export const showSuccessAlertAction = payload => ({
  type: SHOW_SUCCESS_ALERT,
  payload: payload
})
export const showErrorAlertAction = payload => ({
  type: SHOW_ERROR_ALERT,
  payload: payload
})
export const closeAlertAction = () => ({
  type: CLOSE_ALERT
})
