import * as actionTypes from './RequestActions';

const initialState = {
  showRequestModal: false,
  showCustomerRequestModal: false,
  RequestObj: null,
  refreshTable: false,
  tableInstance: {
    searchText: '',
    filterClause: ''
  },
  classificationTypes:[]

};

const  requestReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SHOW_REQUEST_MODAL:
      return {
        ...state,
        RequestObj: null,
        showRequestModal: true
      };
    case actionTypes.CLOSE_REQUEST_MODAL:
      return {
        ...state,
        RequestObj: null,        
        showRequestModal: false,
        showCustomerRequestModal: false
      };
    case actionTypes.SET_VEHICLE_REQUEST:
      return {
        ...state,
        RequestObj: payload,
        showRequestModal: true //opens modal when setting ONLINEVEHICLE
      };
      case actionTypes.SET_CUSTOMER_REQUEST:
      return {
        ...state,
        RequestObj: payload,
        showCustomerRequestModal: true //opens modal when setting ONLINEVEHICLE
      };

    case actionTypes.DELETE_REQUEST:
      return {
        ...state
      };
      
    case actionTypes.UPDATE_REQUEST:
    case actionTypes.SAVE_REQUEST:
      return {
        ...state,
        showRequestModal: false,
        showCustomerRequestModal: false,
        RequestObj: null
      }
    
    case actionTypes.SET_TABLE_SEARCH:
      return {
        ...state,
        tableInstance: { ...state.tableInstance, searchText: payload }
      }

    case actionTypes.SET_TABLE_FILTER:
      return {
        ...state,
        tableInstance: { ...state.tableInstance, filterClause: payload }
      }

    case actionTypes.REFRESH_TABLE:
      return {
        ...state,
        refreshTable: true
      }

    case actionTypes.REFRESH_TABLE_COMPLETE:
      return {
        ...state,
        refreshTable: false
      }

      case actionTypes.GET_CLASSIFICATIONS:
        return {
          ...state,
          classificationTypes: payload 
        }


    default:

      return state;
  }
};

export default  requestReducer;
