import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  Typography
} from '@material-ui/core';
import * as actionTypes from '../../store/Auth/AuthActions';
import * as yup from 'yup';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { Facebook as FacebookIcon, Google as GoogleIcon } from 'icons';

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required('Email Address is required'),
  password: yup.string().required('Password is required')
});

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const SignIn = props => {
  const { history } = props;

  const dispatch = useDispatch();
  const classes = useStyles();

  const login = userObj => dispatch(actionTypes.authenticateUser(userObj));
  const { register, handleSubmit, errors, setValue } = useForm({
    mode: 'onBlur',
    validationSchema: schema
  });

  //Form Submit
  const onSubmit = data => {
    login(data);
    setValue('password', '');
  };
  const handleBack = () => {
    history.goBack();
  };

  const handleSignIn = event => {
    event.preventDefault();
    history.push('/');
  };

  return (
    <div className={classes.root}>
      <Grid
        alignItems="center"
        className={classes.grid}
        container
        justify="center">
        <Grid className={classes.content} item lg={6} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                noValidate
                onSubmit={handleSubmit(onSubmit)}>
                <Card raised>
                  <CardContent>
                    <Typography className={classes.title} variant="h2">
                      Sign in
                    </Typography>

                    <Typography
                      align="center"
                      className={classes.sugestion}
                      color="textSecondary"
                      variant="body1">
                      Login with email address
                    </Typography>
                    <TextField
                      className={classes.textField}
                      error={errors.email ? true : false}
                      fullWidth
                      helperText={errors.email && errors.email.message}
                      inputRef={register}
                      label="Email Address"
                      name="email"
                      placeholder="Email Address"
                      required
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      error={errors.password ? true : false}
                      fullWidth
                      helperText={errors.password && errors.password.message}
                      inputRef={register}
                      label="Password"
                      name="password"
                      placeholder="Password"
                      required
                      type="password"
                      variant="outlined"
                    />
                    <Button
                      className={classes.signInButton}
                      color="primary"
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained">
                      Sign in now
                    </Button>
                    <Typography color="textSecondary" variant="body1">
                      Don't have an account?{' '}
                      <Link component={RouterLink} to="/sign-up" variant="h6">
                        Sign up
                      </Link>
                    </Typography>
                    <Typography color="textSecondary" variant="body1">
                      Forgot your password?{' '}
                      <Link
                        component={RouterLink}
                        to="/forgot-password"
                        variant="h6">
                        Forgot Password
                      </Link>
                    </Typography>
                  </CardContent>
                </Card>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignIn);
