import React, { useState } from 'react';
import {
  TextField,
  makeStyles,
  Chip,
  CssBaseline,
  Grid,
  Paper,
  FormGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Switch
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  dropzoneArea: {
    minHeight: 0
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const CurrentCustomerDetails = props => {
  const { Customer } = props;

  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Name"
          name="name"
          placeholder="Name"
          required
          variant="filled"
          defaultValue={Customer?.name}
          InputProps={{
            readOnly: true
          }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Tax Identification Number"
          name="taxIdentificationNumber"
          placeholder="Tax Identification Number"
          // required
          variant="filled"
          defaultValue={Customer?.tin}
          InputProps={{
            readOnly: true
          }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Identification Number"
          margin="normal"
          name="identificationNumber"
          placeholder="Identification Number"
          // required
          shrink="true"
          variant="filled"
          defaultValue={Customer?.idNumber}
          InputProps={{
            readOnly: true
          }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Email"
          margin="normal"
          name="email"
          placeholder="Email"
          required
          shrink="true"
          variant="filled"
          defaultValue={Customer?.email}
          InputProps={{
            readOnly: true
          }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Mobile Number"
          margin="normal"
          name="mobileNumber"
          placeholder="Mobile Number"
          // required
          shrink="true"
          variant="filled"
          defaultValue={Customer?.mobile}
          InputProps={{
            readOnly: true
          }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Home Number"
          margin="normal"
          name="homeNumber"
          placeholder="Home Number"
          // required
          shrink="true"
          variant="filled"
          defaultValue={Customer?.home}
          InputProps={{
            readOnly: true
          }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Office Number"
          margin="normal"
          name="officeNumber"
          placeholder="Office Number"
          shrink="true"
          variant="filled"
          defaultValue={Customer?.office}
          InputProps={{
            readOnly: true
          }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <FormControl component="fieldset">
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={Customer?.company}
                  color="primary"
                  name="isCompany"
                  inputProps={{
                    'aria-label': 'primary checkbox'
                  }}
                />
              }
              label="Is Company"
            />
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};
export default CurrentCustomerDetails;
