import Axios from '../../axios/authAxios';
import AuthAxios from '../../axios/authAxiosWithBearer';
import {
  showSuccessAlertAction,
  showErrorAlertAction,
  blockInputAction,
  unblockInputAction
} from '../General/GeneralActions';
import { LocalStorageService } from 'helpers';
import history from 'helpers/history';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
// LocalstorageService
const localStorageService = LocalStorageService.getService();

export const REGISTER_USER = 'REGISTER_USER';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

//DATA ACTIONS
export const registerUserAction = userInfo => ({
  type: REGISTER_USER,
  payload: userInfo
});
export const loginAction = userInfo => ({ type: LOGIN, payload: userInfo });
export const logoutAction = () => ({ type: LOGOUT });
export const checkAuthTimeout = expirationTime => {
  return dispatch => {
    setTimeout(() => {
      dispatch(refreshToken());
    }, expirationTime * 1000);
  };
};

export const registerUser = userInfo => {
  userInfo.callback_url = 'https://www.dhbcportaltest.ssi.gy/confirm-email';

  return dispatch => {
    dispatch(blockInputAction());
    dispatch(showLoading());
    Axios.post('account/RegisterUser', userInfo)
      .then(response => {
        dispatch(hideLoading());

        const data = response.data;
        if (!data.success) {
          dispatch(showErrorAlertAction({ message: data.message }));
        } else {
          dispatch(registerUserAction(data));
          dispatch(showSuccessAlertAction({ message: data.message }));
          history.push('/sign-in');
        }
      })
      .catch(error => {
        dispatch(showErrorAlertAction());
      })
      .finally(() => {
        dispatch(unblockInputAction());
      });
  };
};

export const changePassword = userInfo => {
  return dispatch => {
    dispatch(blockInputAction());
    dispatch(showLoading());
    AuthAxios.post('account/changePassword', userInfo)
      .then(response => {
        dispatch(hideLoading());

        const data = response.data;
        if (!data.success) {
          dispatch(showErrorAlertAction({ message: data.message }));
        } else {
          dispatch(showSuccessAlertAction({ message: data.message }));
        }
      })
      .catch(error => {
        dispatch(showErrorAlertAction());
      })
      .finally(() => {
        dispatch(unblockInputAction());
      });
  };
};

export const authenticateUser = userInfo => {
  return dispatch => {
    dispatch(blockInputAction());
    dispatch(showLoading());
    Axios.post('account/login', userInfo)
      .then(response => {
        dispatch(hideLoading());

        const data = response.data;
        if (!data.success) {
          dispatch(showErrorAlertAction({ message: data.message }));
        } else {
          data.data.expiration_date = new Date(
            new Date().getTime() + data.data.expires_in * 1000
          );
          localStorageService.setToken(data.data);

          dispatch(loginAction(data.data));
          //dispatch(checkAuthTimeout(data.data.expires_in));
          history.push('/');
          dispatch(
            showSuccessAlertAction({ message: 'Successfully logged in' })
          );
        }
      })
      .catch(error => {
        dispatch(showErrorAlertAction());
      })
      .finally(() => {
        dispatch(unblockInputAction());
      });
  };
};

export const logOutUser = () => {
  return dispatch => {
    localStorageService.clearToken();
    dispatch(logoutAction());
    history.push('/sign-in');
  };
};

export const forgotPassword = userInfo => {
  userInfo.callback_url = 'https://www.dhbcportaltest.ssi.gy/password-reset';
  return dispatch => {
    dispatch(blockInputAction());
    dispatch(showLoading());
    Axios.post('account/forgotPassword', userInfo)
      .then(response => {
        dispatch(hideLoading());

        const data = response.data;
        if (!data.success) {
          dispatch(showErrorAlertAction({ message: data.message }));
        } else {
          dispatch(showSuccessAlertAction({ message: data.message }));
          history.push('/sign-in');
        }
      })
      .catch(error => {
        dispatch(showErrorAlertAction());
      })
      .finally(() => {
        dispatch(unblockInputAction());
      });
  };
};

export const resetPassword = userInfo => {
  return dispatch => {
    dispatch(blockInputAction());
    dispatch(showLoading());
    Axios.post('account/resetPassword', userInfo)
      .then(response => {
        dispatch(hideLoading());

        const data = response.data;
        if (!data.success) {
          dispatch(showErrorAlertAction({ message: data.message }));
        } else {
          //dispatch(showSuccessAlertAction({ message: data.message }));
          history.push('/reset-password-confirmation');
        }
      })
      .catch(error => {
        dispatch(showErrorAlertAction());
      })
      .finally(() => {
        dispatch(unblockInputAction());
      });
  };
};

export const refreshToken = () => {
  return dispatch => {
    const refreshToken = localStorageService.getRefreshToken();
    if (refreshToken && refreshToken !== 'undefined') {
      Axios.post('/account/RefreshToken', {
        token: refreshToken
      })
        .then(response => {
          const data = response.data;
          if (!data.success) {
            dispatch(logOutUser());
          } else {
            data.data.expiration_date = new Date(
              new Date().getTime() + data.data.expires_in * 1000
            );
            localStorageService.setToken(data.data);
            dispatch(checkAuthTimeout(data.data.expires_in));
          }
        })
        .catch(error => {
          dispatch(logOutUser());
        });
    } else {
      dispatch(logOutUser());
    }
  };
};
