import Axios from '../../axios/axios';
import {
  showSuccessAlertAction,
  showErrorAlertAction,
  blockInputAction,
  unblockInputAction
} from '../General/GeneralActions';

import { showLoading, hideLoading } from 'react-redux-loading-bar';

export const SHOW_ONLINEVEHICLE_MODAL = 'SHOW_ONLINEVEHICLE_MODAL';
export const CLOSE_ONLINEVEHICLE_MODAL = 'CLOSE_ONLINEVEHICLE_MODAL';
export const SHOW_ONLINEVEHICLE_ALERT = 'SHOW_ONLINEVEHICLE_ALERT';
export const CLOSE_ONLINEVEHICLE_ALERT = 'CLOSE_ONLINEVEHICLE_ALERT';

export const SET_ONLINEVEHICLE = 'SET_ONLINEVEHICLE';
export const DELETE_ONLINEVEHICLE = 'DELETE_ONLINEVEHICLE';
export const SAVE_ONLINEVEHICLE = 'SAVE_ONLINEVEHICLE';
export const UPDATE_ONLINEVEHICLE = 'UPDATE_ONLINEVEHICLE';
export const ACTION_FAILED = 'ACTION_FAILED';

export const SET_TABLE_SEARCH = 'SET_TABLE_SEARCH';
export const SET_TABLE_FILTER = 'SET_TABLE_FILTER';
export const SET_TABLE_LOADING = 'SET_TABLE_LOADING';
export const REFRESH_TABLE = 'REFRESH_TABLE';
export const REFRESH_TABLE_COMPLETE = 'REFRESH_TABLE_COMPLETE';
export const CLOSE_TABLE_DELETE_CONFIRM = 'CLOSE_TABLE_DELETE_CONFIRM';
export const OPEN_TABLE_DELETE_CONFIRM = 'OPEN_TABLE_DELETE_CONFIRM';
export const SET_SELECTED_ID = 'SET_SELECTED_ID';

//MODAL ACTIONS
export const showOnlineVehicleModalAction = () => ({
  type: SHOW_ONLINEVEHICLE_MODAL
});
export const closeOnlineVehicleModalAction = () => ({
  type: CLOSE_ONLINEVEHICLE_MODAL
});

//ALERT ACTIONS
export const showOnlineVehicleAlertAction = () => ({
  type: SHOW_ONLINEVEHICLE_ALERT
});
export const closeOnlineVehicleAlertAction = () => ({
  type: CLOSE_ONLINEVEHICLE_ALERT
});

//TABLE ACTIONS
export const setTableSearch = payload => ({
  type: SET_TABLE_SEARCH,
  payload: payload
});

export const setTableFilter = payload => ({
  type: SET_TABLE_FILTER,
  payload: payload
});

export const refreshTable = () => ({
  type: REFRESH_TABLE
});

export const refreshTableComplete = () => ({
  type: REFRESH_TABLE_COMPLETE
});

export const openTableDeleteConfirmAction = () => ({
  type: OPEN_TABLE_DELETE_CONFIRM
});

export const closeTableDeleteConfirmAction = () => ({
  type: CLOSE_TABLE_DELETE_CONFIRM
});

export const setSelectedIdAction = payload => ({
  type: SET_SELECTED_ID,
  payload: payload
});

//DATA ACTIONS
export const setOnlineVehicleAction = onlineVehicleObj => ({
  type: SET_ONLINEVEHICLE,
  payload: onlineVehicleObj
});

export const deleteOnlineVehicleAction = deleteResponse => ({
  type: DELETE_ONLINEVEHICLE,
  payload: deleteResponse
});

export const saveOnlineVehicleAction = saveResponse => ({
  type: SAVE_ONLINEVEHICLE,
  payload: saveResponse
});

export const updateOnlineVehicleAction = updateResponse => ({
  type: UPDATE_ONLINEVEHICLE,
  payload: updateResponse
});

export const fetchOnlineVehicle = (getUrl, id) => {
  return dispatch => {
    dispatch(showLoading());

    Axios.get(getUrl + id)
      .then(response => {
        dispatch(hideLoading());

        const data = response.data;
        if (!data.success) {
          dispatch(showErrorAlertAction({ message: data.message }));
        } else {
          dispatch(setOnlineVehicleAction(data.data));
        }
      })
      .catch(error => {
        dispatch(showErrorAlertAction());
      });
  };
};

export const deleteOnlineVehicle = (deleteUrl, id) => {
  return dispatch => {
    dispatch(blockInputAction());
    dispatch(showLoading());

    Axios.delete(deleteUrl + id)
      .then(response => {
        dispatch(hideLoading());

        const data = response.data;
        if (!data.success) {
          dispatch(showErrorAlertAction({ message: data.message }));
        } else {
          dispatch(deleteOnlineVehicleAction(data));
          dispatch(showSuccessAlertAction({ message: data.message }));
          dispatch(refreshTable());
        }
      })
      .catch(error => {
        dispatch(showErrorAlertAction());
      })
      .finally(() => {
        dispatch(refreshTableComplete());
        dispatch(unblockInputAction());
      });
  };
};

export const saveOnlineVehicle = (saveUrl, onlineVehicleObj) => {
  return dispatch => {
    dispatch(blockInputAction());
    dispatch(showLoading());

    const formData = new FormData();

    formData.append('Document', onlineVehicleObj.file);
    formData.append('LicencePlate', onlineVehicleObj.licencePlateNumber);
    formData.append('Color', onlineVehicleObj.color);

    Axios.post(saveUrl, formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
      .then(response => {
        dispatch(hideLoading());

        const data = response.data;
        if (!data.success) {
          dispatch(showErrorAlertAction({ message: data.message }));
        } else {
          dispatch(saveOnlineVehicleAction(data));
          dispatch(showSuccessAlertAction({ message: data.message }));
          dispatch(refreshTable());
        }
      })
      .catch(error => {
        dispatch(showErrorAlertAction());
      })
      .finally(() => {
        dispatch(refreshTableComplete());
        dispatch(unblockInputAction());
      });
  };
};

export const saveOnlineVehicleDoc = data => {
  return dispatch => {
    dispatch(blockInputAction());
    dispatch(showLoading());
    const formData = new FormData();

    formData.append('Document', data.file);
    formData.append('FkOnlineVehicle', '9f1d2982-ad8d-41f6-9406-ade5803120ce');
    // const vehicleObj = {
    //   LicencePlate: onlineVehicleObj.licencePlateNumber,
    //   Color: onlineVehicleObj.color,
    //   Customer: onlineVehicleObj.fkOnlineCustomer
    // };

    Axios.post('vehicle/createOnlineVehicleDocument', formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
      .then(response => {
        dispatch(hideLoading());

        const data = response.data;
        if (!data.success) {
          dispatch(showErrorAlertAction({ message: data.message }));
        } else {
          //dispatch(saveOnlineVehicleAction(data));
          dispatch(showSuccessAlertAction({ message: data.message }));
        }
      })
      .catch(error => {
        dispatch(showErrorAlertAction());
      })
      .finally(() => {
        dispatch(refreshTableComplete());
        dispatch(unblockInputAction());
      });
  };
};

export const updateOnlineVehicle = (updateUrl, onlineVehicleObj) => {
  return dispatch => {
    dispatch(blockInputAction());
    dispatch(showLoading());
    const vehicleObj = {
      LicencePlate: onlineVehicleObj.licencePlateNumber,
      Color: onlineVehicleObj.color,
      Customer: onlineVehicleObj.fkOnlineCustomer
    };
    const formData = new FormData();

    formData.append('Id', onlineVehicleObj.id);
    formData.append('Document', onlineVehicleObj.file);
    formData.append('LicencePlate', onlineVehicleObj.licencePlateNumber);
    formData.append('Color', onlineVehicleObj.color);
    formData.append('DocumentInfo[Id]', onlineVehicleObj.fileId);
    formData.append('DocumentInfo[FileName]', onlineVehicleObj.fileName);


    Axios.post(updateUrl, formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
      .then(response => {
        dispatch(hideLoading());

        const data = response.data;
        if (!data.success) {
          dispatch(showErrorAlertAction({ message: data.message }));
        } else {
          dispatch(updateOnlineVehicleAction(data));
          dispatch(showSuccessAlertAction({ message: data.message }));
          dispatch(refreshTable());
        }
      })
      .catch(error => {
        dispatch(showErrorAlertAction());
      })
      .finally(() => {
        dispatch(refreshTableComplete());
        dispatch(unblockInputAction());
      });
  };
};
