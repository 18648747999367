import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  TextField,
  makeStyles,
  Chip,
  CssBaseline,
  Grid,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Card,
  CardContent,
  CardHeader,
  FormGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Switch
} from '@material-ui/core';
import * as yup from 'yup';
import * as actionTypes from 'store/General/GeneralActions';
import ReduxBlockUi from 'react-block-ui/redux';
import CurrentCustomerDetails from '../CurrentCustomerDetails/CurrentCustomerDetails';
import { CardBody, CardTitle } from 'reactstrap';

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  // taxIdentificationNumber: yup
  //   .string()
  //   .required('Tax Identification Number is required'),
  // identificationNumber: yup
  //   .string()
  //   .required('Identification Number is required'),
  email: yup.string().required('Email is required')
  // mobileNumber: yup.string().required('Mobile Number is required'),
  // homeNumber: yup.string().required('Home Number is required')
});

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  dropzoneArea: {
    minHeight: 0
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const CustomerRequestForm = props => {
  const {
    Customer,
    onCloseModal,
    isModalOpen,
    pageTitle,
    handleOnSubmit,
    handleOnApprovalSubmit,
    handleOnRejectSubmit
  } = props;

  const classes = useStyles();
  const [isCompany, setIsCompany] = useState(
    Customer?.internalData?.company ?? false
  );

  const handleChange = event => {
    setIsCompany(event.target.checked);
  };

  const { register, handleSubmit, errors, setValue } = useForm({
    validationSchema: schema
  });

  //Form Submit
  const onApproval = data => {
    var requestPayload = {
      id: Customer.requestId,
      comments: data.comments,
      requestStatus: 'B9231F99-A51B-4C1B-B282-2F8337FC14BB',
      customerDetail: {
        name: data.name,
        taxIdentificationNumber: data.taxIdentificationNumber,
        identificationNumber: data.identificationNumber,
        email: data.email,
        mobileNumber: data.mobileNumber,
        homeNumber: data.homeNumber,
        officeNumber: data.officeNumber,
        company: data.isCompany
      }
    };
    handleOnApprovalSubmit(requestPayload);
  };

  const onDecline = data => {
    var requestPayload = {
      id: Customer.requestId,
      comments: data.comments,
      requestStatus: 'EB17A0A2-4441-4300-A731-50F49893D078'
      // customerDetail : {
      //   name: data.name,
      //   taxIdentificationNumber: data.taxIdentificationNumber,
      //   identificationNumber: data.identificationNumber,
      //   email: data.email,
      //   mobileNumber: data.mobileNumber,
      //   homeNumber: data.homeNumber,
      //   officeNumber: data.officeNumber,
      //   //company: data.isCompany,
      // }
    };
    handleOnRejectSubmit(requestPayload);
  };
  const handleEntered = () => {
    setValue('name', '');
    setValue('taxIdentificationNumber', '');
    setValue('identificationNumber', '');
    setValue('email', '');
    setValue('mobileNumber', '');
    setValue('homeNumber', '');
    setValue('officeNumber', '');
    setValue('isCompany', '');
    //If props.selectedObject not null Fill state
    if (Customer) {
      const onlineCustomer = Customer.onlineData;
      setValue('name', onlineCustomer.name);
      setValue('taxIdentificationNumber', onlineCustomer.tin);
      setValue('identificationNumber', onlineCustomer.idNumber);
      setValue('email', onlineCustomer.email);
      setValue('mobileNumber', onlineCustomer.mobile);
      setValue('homeNumber', onlineCustomer.home);
      setValue('officeNumber', onlineCustomer.office);
      setValue('isCompany', onlineCustomer.company);
    }
  };

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      onClose={onCloseModal}
      onEntered={() => handleEntered()}
      open={isModalOpen}
      maxWidth="lg">
      <ReduxBlockUi
        tag="div"
        block={actionTypes.BLOCK_INPUT}
        unblock={actionTypes.UNBLOCK_INPUT}
        onChange={() => {}}>
        <form noValidate>
          <DialogTitle id="form-dialog-title">{pageTitle}</DialogTitle>
          <CssBaseline />
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  inputRef={register}
                  label="Comment"
                  margin="normal"
                  name="comments"
                  placeholder="Comment"
                  // required
                  shrink="true"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Card>
                  <CardHeader title="Updated Customer Details" />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item md={12} xs={12}>
                        <TextField
                          error={errors.name ? true : false}
                          fullWidth
                          helperText={errors.name && errors.name.message}
                          InputLabelProps={{ shrink: true }}
                          inputRef={register}
                          label="Name"
                          name="name"
                          placeholder="Name"
                          required
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <TextField
                          error={errors.taxIdentificationNumber ? true : false}
                          fullWidth
                          helperText={
                            errors.taxIdentificationNumber &&
                            errors.taxIdentificationNumber.message
                          }
                          id="taxIdentificationNumber"
                          InputLabelProps={{ shrink: true }}
                          inputRef={register}
                          label="Tax Identification Number"
                          name="taxIdentificationNumber"
                          placeholder="Tax Identification Number"
                          // required
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <TextField
                          error={errors.identificationNumber ? true : false}
                          fullWidth
                          helperText={
                            errors.identificationNumber &&
                            errors.identificationNumber.message
                          }
                          id="identificationNumber"
                          InputLabelProps={{ shrink: true }}
                          inputRef={register}
                          label="Identification Number"
                          margin="normal"
                          name="identificationNumber"
                          placeholder="Identification Number"
                          // required
                          shrink="true"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <TextField
                          error={errors.email ? true : false}
                          fullWidth
                          helperText={errors.email && errors.email.message}
                          id="email"
                          InputLabelProps={{ shrink: true }}
                          inputRef={register}
                          label="Email"
                          margin="normal"
                          name="email"
                          placeholder="Email"
                          required
                          shrink="true"
                          variant="filled"
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <TextField
                          error={errors.mobileNumber ? true : false}
                          fullWidth
                          helperText={
                            errors.mobileNumber && errors.mobileNumber.message
                          }
                          id="mobileNumber"
                          InputLabelProps={{ shrink: true }}
                          inputRef={register}
                          label="Mobile Number"
                          margin="normal"
                          name="mobileNumber"
                          placeholder="Mobile Number"
                          //required
                          shrink="true"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <TextField
                          error={errors.homeNumber ? true : false}
                          fullWidth
                          helperText={
                            errors.homeNumber && errors.homeNumber.message
                          }
                          id="homeNumber"
                          InputLabelProps={{ shrink: true }}
                          inputRef={register}
                          label="Home Number"
                          margin="normal"
                          name="homeNumber"
                          placeholder="Home Number"
                          // required
                          shrink="true"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <TextField
                          error={errors.officeNumber ? true : false}
                          fullWidth
                          helperText={
                            errors.officeNumber && errors.officeNumber.message
                          }
                          id="officeNumber"
                          InputLabelProps={{ shrink: true }}
                          inputRef={register}
                          label="Office Number"
                          margin="normal"
                          name="officeNumber"
                          placeholder="Office Number"
                          shrink="true"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <FormControl component="fieldset">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={isCompany}
                                  onChange={handleChange}
                                  inputRef={register}
                                  color="primary"
                                  name="isCompany"
                                  inputProps={{
                                    'aria-label': 'primary checkbox'
                                  }}
                                />
                              }
                              label="Is Company"
                            />
                          </FormGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={6} xs={12}>
                {/* <CurrentCustomerDetails Customer={Customer.internalData} /> */}
                <Card>
                  <CardHeader title="Current Customer Details" />
                  <CardContent>
                    <CurrentCustomerDetails Customer={Customer?.internalData} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={onCloseModal} variant="outlined">
              Cancel
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={handleSubmit(onDecline)}>
              Reject
            </Button>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              onClick={handleSubmit(onApproval)}>
              Approve
            </Button>
          </DialogActions>
        </form>
      </ReduxBlockUi>
    </Dialog>
  );
};
export default CustomerRequestForm;
