import * as actionTypes from './GeneralActions'

const initialState = {
  blockInput: false,
  alertOptions: {
    showAlert: false,
    message: '',
    severity: ''
  }
}

const generalReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SHOW_ALERT:
      return {
        ...state,
        alertOptions: {
          ...state.alertOptions,
          showAlert: true,
          message: payload?.message,
          severity: payload?.severity
        }
      }
    case actionTypes.SHOW_SUCCESS_ALERT:
      return {
        ...state,
        alertOptions: {
          ...state.alertOptions,
          showAlert: true,
          message: payload?.message,
          severity: 'success'
        }
      }
    case actionTypes.SHOW_ERROR_ALERT:
      return {
        ...state,
        alertOptions: {
          ...state.alertOptions,
          showAlert: true,
          message: payload?.message ?? 'Error Processing Request!',
          severity: 'error'
        }
      }
    case actionTypes.CLOSE_ALERT:
      return {
        ...state,
        alertOptions: {
          ...state.alertOptions,
          showAlert: false,
          message: '',
          severity: ''
        }
      }

      case actionTypes.BLOCK_INPUT:
        return {
          ...state,
          blockInput: true
        };
      case actionTypes.UNBLOCK_INPUT:
        return {
          ...state,
          blockInput: false
        };

    default:
      return state
  }
}

export default generalReducer
