import axios from 'axios';

const authInstance = axios.create({
  baseURL: 'https://dhbcidentitytest.ssi.gy/api'
});

//instance.defaults.headers.common['Authorization'] = 'AUTH TOKEN FROM INSTANCE';

// instance.interceptors.request...

export default authInstance;
